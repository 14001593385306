export const A7672 =
    [
        {
            id: 4,
            code: "A-7672-11",
            name: "Сумка-рюкзак вертикальная",
            link: "https://lihitlab-jp.ru/images/A7672/A-7672-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50311155/detail.aspx",

        },
        {
            id: 6,
            code: "A-7672-24",
            name: "Сумка-рюкзак вертикальная",
            link: "https://lihitlab-jp.ru/images/A7672/A-7672-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50311156/detail.aspx",

        },
       
    ]

export const A7672POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7672/A-7672_poi1.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7672/A-7672_opn1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7672/A-7672_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7672/A-7672_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7672/A-7672_poi4.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7672/A-7672_img1.jpg",
        },        

    ]