export const A7576 =
    [
        {
            id: 1,
            code: "A-7576-4",
            name: "Папка-сумка для гаджетов B5",
            link: "https://lihitlab-jp.ru/images/A7576/A-7576-4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50218969/detail.aspx",

        },
        {
            id: 2,
            code: "A-7576-11",
            name: "Папка-сумка для гаджетов B5",
            link: "https://lihitlab-jp.ru/images/A7576/A-7576-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50218970/detail.aspx",

        },
        {
            id: 3,
            code: "A-7576-22",
            name: "Папка-сумка для гаджетов B5",
            link: "https://lihitlab-jp.ru/images/A7576/A-7576-22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50218971/detail.aspx",

        },
        {
            id: 4,
            code: "A-7576-24",
            name: "Папка-сумка для гаджетов B5",
            link: "https://lihitlab-jp.ru/images/A7576/A-7576-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50218972/detail.aspx",

        },
        {
            id: 5,
            code: "A-7576-31",
            name: "Папка-сумка для гаджетов B5",
            link: "https://lihitlab-jp.ru/images/A7576/A-7576-31.jpg",
            color: "Камуфляж",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164013793/detail.aspx",

        },
       
    ]

export const A7576POI =
    [
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7576/A-7576_poi2.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7576/A-7576_poi5.jpg",
        },
        {
            id: 10,
            link: "https://lihitlab-jp.ru/images/A7576/A-7576_poi10.jpg",
        },
        {
            id: 11,
            link: "https://lihitlab-jp.ru/images/A7576/A-7576_poi11.jpg",
        },

    ]