export const A7718 =
    [
        {
            id: 1,
            code: "A-7718-1",
            name: "Брелок с звуковой сигнализацией Медвежонок",
            link: "https://lihitlab-jp.ru/images/A7718/A-7718-1.jpg",
            color: "Коричневый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49356916/detail.aspx",

        },
        {
            id: 2,
            code: "A-7718-2",
            name: "Брелок с звуковой сигнализацией Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7718/A-7718-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49356917/detail.aspx",
        },
        {
            id: 3,
            code: "A-7718-3",
            name: "Брелок с звуковой сигнализацией Черный кот",
            link: "https://lihitlab-jp.ru/images/A7718/A-7718-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49356918/detail.aspx",
        },
        {
            id: 4,
            code: "A-7718-4",
            name: "Брелок с звуковой сигнализацией Серый кот",
            link: "https://lihitlab-jp.ru/images/A7718/A-7718-4.jpg",
            color: "Серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49356919/detail.aspx",
        },
        {
            id: 5,
            code: "A-7718-5",
            name: "Брелок с звуковой сигнализацией Свинка",
            link: "https://lihitlab-jp.ru/images/A7718/A-7718-5.jpg",
            color: "Розовый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49356920/detail.aspx",
        },
        {
            id: 6,
            code: "A-7718-6",
            name: "Брелок с звуковой сигнализацией Панда",
            link: "https://lihitlab-jp.ru/images/A7718/A-7718-6.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49356921/detail.aspx",
        },
    ]


export const A7718POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7718/A-7718_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7718/A-7718_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7718/A-7718_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7718/A-7718_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7718/A-7718_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7718/A-7718_poi6.jpg",
        },

    ]