export const A7902 =
    [
        {
            id: 1,
            code: "A-7902-3",
            name: "Пенал двухсторонний L-образный",
            link: "https://lihitlab-jp.ru/images/A7902/A-7902_3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186119622/detail.aspx",

        },
        {
            id: 2,
            code: "A-7902-5",
            name: "Пенал двухсторонний L-образный",
            link: "https://lihitlab-jp.ru/images/A7902/A-7902_5.jpg",
            color: "Желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186119627/detail.aspx",

        },
        {
            id: 3,
            code: "A-7902-11",
            name: "Пенал двухсторонний L-образный",
            link: "https://lihitlab-jp.ru/images/A7902/A-7902_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186119628/detail.aspx",

        },
        {
            id: 4,
            code: "A-7902-16",
            name: "Пенал двухсторонний L-образный",
            link: "https://lihitlab-jp.ru/images/A7902/A-7902_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186119629/detail.aspx",

        },
        {
            id: 5,
            code: "A-7902-24",
            name: "Пенал двухсторонний L-образный",
            link: "https://lihitlab-jp.ru/images/A7902/A-7902_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186119630/detail.aspx",

        },
       
    ]

export const A7902POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7902/A-7902_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7902/A-7902_poi2.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7902/A-7902_ist1.jpg",
        },
        

    ]