export const N1961 =
    [
        {
            id: 1,
            code: "N-1961-2",
            name: "Блокнот перекидной на пружине формата B7 со сменными листами (40 листов)",
            link: "https://lihitlab-jp.ru/images/N1961/N-1961_2.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163769189/detail.aspx",
        },
        {
            id: 2,
            code: "N-1961-3",
            name: "Блокнот перекидной на пружине формата B7 со сменными листами (40 листов)",
            link: "https://lihitlab-jp.ru/images/N1961/N-1961_3.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163769190/detail.aspx",
        },
        {
            id: 3,
            code: "N-1961-5",
            name: "Блокнот перекидной на пружине формата B7 со сменными листами (40 листов)",
            link: "https://lihitlab-jp.ru/images/N1961/N-1961_5.jpg",
            color: "Желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163769191/detail.aspx",
        },
        {
            id: 4,
            code: "N-1961-10",
            name: "Блокнот перекидной на пружине формата B7 со сменными листами (40 листов)",
            link: "https://lihitlab-jp.ru/images/N1961/N-1961_10.jpg",
            color: "Фиолетовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163769192/detail.aspx",
        },    
        {
            id: 5,
            code: "N-1961-16",
            name: "Блокнот перекидной на пружине формата B7 со сменными листами (40 листов)",
            link: "https://lihitlab-jp.ru/images/N1961/N-1961_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163769193/detail.aspx",
        },  
        {
            id: 6,
            code: "N-1961-19",
            name: "Блокнот перекидной на пружине формата B7 со сменными листами (40 листов)",
            link: "https://lihitlab-jp.ru/images/N1961/N-1961_19.jpg",
            color: "Светло-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163769195/detail.aspx",
        },    
       
    ]

export const N1961POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N1961/N-1961_poi1.jpg",
        },
      

    ]