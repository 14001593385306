export const A7759 =
    [
        {
            id: 1,
            code: "A-7759-11",
            name: "Пенал-тубус с магнитной крышкой",
            link: "https://lihitlab-jp.ru/images/A7759/A-7759-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50237217/detail.aspx",

        },
        {
            id: 2,
            code: "A-7759-22",
            name: "Пенал-тубус с магнитной крышкой",
            link: "https://lihitlab-jp.ru/images/A7759/A-7759-22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50237218/detail.aspx",

        },
        {
            id: 3,
            code: "A-7759-24",
            name: "Пенал-тубус с магнитной крышкой",
            link: "https://lihitlab-jp.ru/images/A7759/A-7759-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50237219/detail.aspx",

        },

    ]

export const A7759POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7759/A-7759_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7759/A-7759_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7759/A-7759_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7759/A-7759_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7759/A-7759_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7759/A-7759_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7759/A-7759_poi7.jpg",
        },

    ]