export const A7781 =
    [
        {
            id: 2,
            code: "A-7781-2",
            name: "Пенал лежащий, длинный Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7781/A-7781-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49550163/detail.aspx",
        },
        {
            id: 3,
            code: "A-7781-3",
            name: "Пенал лежащий, длинный Черный кот",
            link: "https://lihitlab-jp.ru/images/A7781/A-7781-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49550164/detail.aspx",
        },
        {
            id: 4,
            code: "A-7781-4",
            name: "Пенал лежащий, длинный Cерый кот",
            link: "https://lihitlab-jp.ru/images/A7781/A-7781-4.jpg",
            color: "Серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49550165/detail.aspx",
        },
        {
            id: 7,
            code: "A-7781-7",
            name: "Пенал лежащий, длинный Белый кот",
            link: "https://lihitlab-jp.ru/images/A7781/A-7781-7.jpg",
            color: "белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49550166/detail.aspx",
        },
        {
            id: 10,
            code: "A-7781-10",
            name: "Пенал лежащий, длинный Пингвин",
            link: "https://lihitlab-jp.ru/images/A7781/A-7781-10.jpg",
            color: "Черно-серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49550167/detail.aspx",
        },
        {
            id: 16,
            code: "A-7781-16",
            name: "Пенал лежащий, длинный Морской Котик",
            link: "https://lihitlab-jp.ru/images/A7781/A-7781-16.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49550168/detail.aspx",
        },
        {
            id: 17,
            code: "A-7781-17",
            name: "Пенал лежащий, длинный Цыпленок",
            link: "https://lihitlab-jp.ru/images/A7781/A-7781-17.jpg",
            color: "Желтый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49550169/detail.aspx",
        },
        {
            id: 18,
            code: "A-7781-18",
            name: "Пенал лежащий, длинный Песик Мопс",
            link: "https://lihitlab-jp.ru/images/A7781/A-7781-18.jpg",
            color: "Желтый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49550170/detail.aspx",
        },
    ]


export const A7781POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7781/A-7781_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7781/A-7781_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7781/A-7781_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7781/A-7781_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7781/A-7781_poi5.jpg",
        },    

    ]