export const A7714 =
    [
        {
            id: 1,
            code: "A-7714-1",
            name: "Пенал-тубус большой Медвежонок",
            link: "https://lihitlab-jp.ru/images/A7714/A-7714-1.jpg",
            color: "Коричневый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47209412/detail.aspx",

        },
        {
            id: 2,
            code: "A-7714-2",
            name: "Пенал-тубус большой Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7714/A-7714-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47209413/detail.aspx",
        },
        {
            id: 3,
            code: "A-7714-3",
            name: "Пенал-тубус большой Черный кот",
            link: "https://lihitlab-jp.ru/images/A7714/A-7714-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47209414/detail.aspx",
        },
        {
            id: 4,
            code: "A-7714-4",
            name: "Пенал-тубус большой Серый кот",
            link: "https://lihitlab-jp.ru/images/A7714/A-7714-4.jpg",
            color: "Серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47209415/detail.aspx",
        },
        {
            id: 5,
            code: "A-7714-5",
            name: "Пенал-тубус большой Свинка",
            link: "https://lihitlab-jp.ru/images/A7714/A-7714-5.jpg",
            color: "Розовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47209416/detail.aspx",
        },
        {
            id: 6,
            code: "A-7714-6",
            name: "Пенал-тубус большой Панда",
            link: "https://lihitlab-jp.ru/images/A7714/A-7714-6.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47209417/detail.aspx",
        },
        {
            id: 7,
            code: "A-7712-7",
            name: "Пенал-тубус большой Белый кот",
            link: "https://lihitlab-jp.ru/images/A7714/A-7714-7.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47209418/detail.aspx",
        },
        {
            id: 8,
            code: "A-7714-8",
            name: "Пенал-тубус большой Бостон терьер",
            link: "https://lihitlab-jp.ru/images/A7714/A-7714-8.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47209419/detail.aspx",
        },
        {
            id: 9,
            code: "A-7714-9",
            name: "Пенал-тубус большой Попугай",
            link: "https://lihitlab-jp.ru/images/A7714/A-7714-9.jpg",
            color: "желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47209420/detail.aspx",
        },
        {
            id: 10,
            code: "A-7714-10",
            name: "Пенал-тубус большой Пингвин",
            link: "https://lihitlab-jp.ru/images/A7714/A-7714-10.jpg",
            color: "черно-серый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47209421/detail.aspx",
        },
        {
            id: 11,
            code: "A-7714-11",
            name: "Пенал-тубус большой Полосатый кот",
            link: "https://lihitlab-jp.ru/images/A7714/A-7714-11.jpg",
            color: "бежевый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47209422/detail.aspx",
        },
        {
            id: 12,
            code: "A-7714-12",
            name: "Пенал-тубус большой Кролик",
            link: "https://lihitlab-jp.ru/images/A7714/A-7714-12.jpg",
            color: "Розовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47209423/detail.aspx",
        },
        {
            id: 13,
            code: "A-7714-13",
            name: "Пенал-тубус большой Лягушка",
            link: "https://lihitlab-jp.ru/images/A7714/A-7714-13.jpg",
            color: "зеленый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47209424/detail.aspx",
        },
        {
            id: 14,
            code: "A-7714-14",
            name: "Пенал-тубус большой Выдра",
            link: "https://lihitlab-jp.ru/images/A7714/A-7714-14.jpg",
            color: "коричневый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47209425/detail.aspx",
        },
        {
            id: 15,
            code: "A-7714-15",
            name: "Пенал-тубус большой Мышка",
            link: "https://lihitlab-jp.ru/images/A7714/A-7714-15.jpg",
            color: "белый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47209426/detail.aspx",
        },
        
    ]


export const A7714POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7714/A-7714_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7714/A-7714_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7714/A-7714_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7714/A-7714_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7714/A-7714_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7714/A-7714_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7714/A-7714_poi7.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7714/A-7714_poi8.jpg",
        },
        {
            id: 9,
            link: "https://lihitlab-jp.ru/images/A7714/A-7714_poi9.jpg",
        },
        {
            id: 10,
            link: "https://lihitlab-jp.ru/images/A7714/A-7714_poi10.jpg",
        },
        {
            id: 11,
            link: "https://lihitlab-jp.ru/images/A7714/A-7714_poi11.jpg",
        },


    ]