export const HIT =
  [
    {
      id: 1,
      code: "A-7712",
      name: "Пенал-тубус малый",
      link: "https://lihitlab-jp.ru/images/A7712/A-7712.jpg",
      cardsRoute: "/catalog/punilabo/a7712",
      bestseller: true,
      new: false,
    },
    {
      id: 3,
      code: "A-7714",
      name: "Пенал-тубус большой",
      link: "https://lihitlab-jp.ru/images/A7714/A-7714.jpg",
      cardsRoute: "/catalog/punilabo/a7714",
      bestseller: true,
      new: false,
    },
    {
      id: 4,
      code: "A-7716",
      name: "Пенал-косметичка",
      link: "https://lihitlab-jp.ru/images/A7716/A-7716.jpg",
      cardsRoute: "/catalog/punilabo/a7716",
      bestseller: true,
      new: false,
    },
    {
      id: 15,
      code: "A-7780",
      name: "Пенал лежащий, мини",
      link: "https://lihitlab-jp.ru/images/A7780/A-7780.jpg",
      cardsRoute: "/catalog/punilabo/a7780",
      bestseller: true,
      new: false,
    },
    {
      id: 16,
      code: "A-7781",
      name: "Пенал лежащий, длинный",
      link: "https://lihitlab-jp.ru/images/A7781/A-7781.jpg",
      cardsRoute: "/catalog/punilabo/a7781",
      bestseller: true,
      new: false,
    },
    {
      id: 24,
      code: "A-7760",
      name: "Бизнес сумка",
      link: "https://lihitlab-jp.ru/images/A7760/A-7760.jpg",
      cardsRoute: "/catalog/altna/a7760",
      bestseller: true,
      new: false,
    },
    {
      id: 31,
      code: "А-7759",
      name: "Пенал-тубус с магнитной крышкой",
      link: "https://lihitlab-jp.ru/images/A7759/A-7759.jpg",
      cardsRoute: "/catalog/altna/a7759",
      bestseller: true,
      new: false,
    },
    {
      id: 70,
      code: "А-7912",
      name: "Папка-сумка двухсторонняя L",
      link: "https://lihitlab-jp.ru/images/A7912/A-7912.jpg",
      cardsRoute: "/catalog/hinemo/a7912",
      bestseller: true,
      new: false,
    },
    {
      id: 35,
      code: "A-7577",
      name: "Папка-сумка для гаджетов А4",
      link: "https://lihitlab-jp.ru/images/A7577/A-7577.jpg",
      cardsRoute: "/catalog/smartfit/a7577",
      bestseller: true,
      new: false,
    },
    {
      id: 65,
      code: "А-7688",
      name: "Пенал-косметичка",
      link: "https://lihitlab-jp.ru/images/A7688/A-7688.jpg",
      cardsRoute: "/catalog/actact/a7688",
      bestseller: true,
      new: false,
    },




  ]