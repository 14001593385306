export const N3103 =
    [
        {
            id: 1,
            code: "N-3103-5",
            name: "Блокнот перекидной на пружине формата A5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N3103/N-3103_5.jpg",
            color: "Желттый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164876477/detail.aspx",
        },
        {
            id: 2,
            code: "N-3103-8",
            name: "Блокнот перекидной на пружине формата A5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N3103/N-3103_8.jpg",
            color: "Голубой",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164876478/detail.aspx",
        },
        {
            id: 3,
            code: "N-3103-10",
            name: "Блокнот перекидной на пружине формата A5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N3103/N-3103_10.jpg",
            color: "Фиолетовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164876479/detail.aspx",
        },
        {
            id: 4,
            code: "N-3103-12",
            name: "Блокнот перекидной на пружине формата A5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N3103/N-3103_12.jpg",
            color: "Розовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164876480/detail.aspx",
        },        
       
    ]

export const N3103POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N3103/N-3103_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/N3103/N-3103_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/N3103/N-3103_poi3.jpg",
        },
      

    ]