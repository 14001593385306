export const A7942 =
    [
        {
            id: 1,
            code: "A-7942-1",
            name: "Пенал-косметичка с прозрачными карманами",
            link: "https://lihitlab-jp.ru/images/A7942/A-7942_1.jpg",
            color: "Прозрачный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137633944/detail.aspx",
        },
        {
            id: 1,
            code: "A-7942-3",
            name: "Пенал-косметичка с прозрачными карманами",
            link: "https://lihitlab-jp.ru/images/A7942/A-7942_3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137633940/detail.aspx",
        },
        {
            id: 2,
            code: "A-7942-8",
            name: "Пенал-косметичка с прозрачными карманами",
            link: "https://lihitlab-jp.ru/images/A7942/A-7942_8.jpg",
            color: "Синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137633939/detail.aspx",
        },
        {
            id: 3,
            code: "A-7942-19",
            name: "Пенал-косметичка с прозрачными карманами",
            link: "https://lihitlab-jp.ru/images/A7942/A-7942_19.jpg",
            color: "Светло-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137633948/detail.aspx",
        },
        {
            id: 4,
            code: "A-7942-24",
            name: "Пенал-косметичка с прозрачными карманами",
            link: "https://lihitlab-jp.ru/images/A7942/A-7942_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137633947/detail.aspx",
        },
       
       
    ]

export const A7942POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7942/A-7942_poi1.jpg",
        },     
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7942/A-7942_poi2.jpg",
        }, 
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7942/A-7942_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7942/A-7942_poi4.jpg",
        },        

    ]