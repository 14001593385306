import './Cards.css';

import { useState, useEffect } from 'react';

import Card from '../Card/Card';

import Cardpoi from '../Cardpoi/Cardpoi';

function Cards(props) {
  return (
    <div class='catalog__cards-list'>
      <h2 class='catalog__cards-list-title'>{props.title}</h2>

      <div className='catalog__main-cards'>
        {props.catalogItem.map((card) => (
          <Card
            link={card.link}
            name={card.name}
            code={card.code}
            color={card.color}
            bestseller={card.bestseller}
            new={card.new}
            linkWB={card.linkWB}
          />
        ))}
      </div>


      

      <h2 class='catalog__cards-list-title'>{props.titlepoi}</h2>

      <div className='catalog__poi-cards'>
        {props.imagespoi.map((cardpoi) => (
          <Cardpoi link={cardpoi.link} />
        ))}
      </div>

      <h2 class='catalog__cards-list-title'>Видео</h2>

      <iframe class='catalog__cards-video'
        width='560'
        height='315'
        src={props.video}
        title='YouTube video player'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        allowfullscreen>
        </iframe>


    </div>
  );
}

export default Cards;
