export const A7683 =
    [
        {
            id: 1,
            code: "A-7683-3",
            name: "Папка-сумка органайзер вертикальная А4",
            link: "https://lihitlab-jp.ru/images/A7683/A-7683-3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50533327/detail.aspx",

        },
        {
            id: 2,
            code: "A-7683-6",
            name: "Папка-сумка органайзер вертикальная А4",
            link: "https://lihitlab-jp.ru/images/A7683/A-7683-6.jpg",
            color: "Желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50533328/detail.aspx",

        },
        {
            id: 3,
            code: "A-7683-11",
            name: "Папка-сумка органайзер вертикальная А4",
            link: "https://lihitlab-jp.ru/images/A7683/A-7683-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50533329/detail.aspx",

        },
        {
            id: 4,
            code: "A-7683-24",
            name: "Папка-сумка органайзер вертикальная А4",
            link: "https://lihitlab-jp.ru/images/A7683/A-7683-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50533330/detail.aspx",

        },
       
    ]

export const A7683POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7683/A-7683_opn1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7683/A-7683_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7683/A-7683_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7683/A-7683_poi3.jpg",
        }, 
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7683/A-7683_poi4.jpg",
        }, 
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7683/A-7683_img1.jpg",
        }, 

    ]