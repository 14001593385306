import './Main.css';

import React from 'react';

import { useState, useEffect } from 'react';

import Baner from '../Baner/Baner';

import New from '../New/New';

import Productline from '../Productline/Productline';








import { NEW } from '../../utils/new/new';

import { HIT } from '../../utils/new/hit';




function Main() {

  const [newcard, setNewcard] = useState(NEW);

  const [hitcard, setHitcard] = useState(HIT);

  return (
  <main className="content">

    <Baner/>

    
    

    <New newsItem={newcard} title={"Новинки"}/>

    <New newsItem={hitcard} title={"Хиты продаж"}/>

    <Productline />

    
  </main>
  );
}

export default Main;
