export const N2608 =
    [
        {
            id: 1,
            code: "N-2608-5",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N2608/N-2608_5.jpg",
            color: "Желттый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163765567/detail.aspx",
        },
        {
            id: 2,
            code: "N-2608-7",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N2608/N-2608_7.jpg",
            color: "Зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163765568/detail.aspx",
        },
        {
            id: 3,
            code: "N-2608-8",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N2608/N-2608_8.jpg",
            color: "Синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163765569/detail.aspx",
        },
        {
            id: 4,
            code: "N-2608-10",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N2608/N-2608_10.jpg",
            color: "Фиолетовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163765570/detail.aspx",
        },
        {
            id: 5,
            code: "N-2608-11",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N2608/N-2608_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163765571/detail.aspx",
        },
        {
            id: 6,
            code: "N-2608-12",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N2608/N-2608_12.jpg",
            color: "Розовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163765572/detail.aspx",
        },
        {
            id: 7,
            code: "N-2608-16",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N2608/N-2608_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163765573/detail.aspx",
        },
        
       
    ]

export const N2608POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N2608/N-2608_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/N2608/N-2608_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/N2608/N-2608_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/N2608/N-2608_poi4.jpg",
        },           

    ]