import './Footer.css';

import headerLogo from '../../images/logo.jpg';


function Footer() {
  return (
    <footer className='footer'>
      
      {/* <div className="footer__overflow"></div> */}

      <div className='footer__wrap'>

        <div className="footer__col">
            <h4 className="footer__col-title">Компания</h4>
            <p className="footer__col-text">footer__col</p>

        </div>

        <div className="footer__col">
            <h4 className="footer__col-title">Контакты</h4>

        </div>

        <div className="footer__col">
            <h4 className="footer__col-title">Контакты</h4>

        </div>

        <div className="footer__col">
            <h4 className="footer__col-title">Мы в соцсетях</h4>
            <a href="https://ok.ru/profile/584413166229" className="footer__col-text">Одноклассники</a>
            <a href="https://vk.com/lihit_lab" className="footer__col-text">В контакте</a>

        </div>






      </div>
    </footer>
  );
}

export default Footer;
