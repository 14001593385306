import './CardImg.css';

import { Link } from 'react-router-dom';

function CardImg(props) {




  return (

    <Link to={props.cardsRoute} className='catalog__card-link'>

    <article className="catalog__card-img">

      <img className="catalog__img" src={props.link} alt={props.name} />
      <h3 className="catalog__card-subtitle">{props.name}</h3>
      <div className='stars'></div>
      <p className="catalog__card-title-img">Артикул: {props.code}</p>
      

      <div className="catalog__card-wrapper-icon">

        <div className={props.new ? "catalog__card-new catalog__card-new-active" :  "catalog__card-new"}>Новинка</div>

        <div className={props.bestseller ? "catalog__card-bestseller catalog__card-bestseller-active" :  "catalog__card-bestseller"}>Хит</div>        


      </div>      


    </article>

    </Link>

  );
}

export default CardImg;
