export const A7595 =
    [
        {
            id: 1,
            code: "A-7595-4",
            name: "Крючек для сумки",
            link: "https://lihitlab-jp.ru/images/A7595/A-7595_4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137730253/detail.aspx",

        },
        {
            id: 2,
            code: "A-7595-16",
            name: "Крючек для сумки",
            link: "https://lihitlab-jp.ru/images/A7595/A-7595_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137730252/detail.aspx",

        },
        {
            id: 3,
            code: "A-7595-24",
            name: "Крючек для сумки",
            link: "https://lihitlab-jp.ru/images/A7595/A-7595_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137730254/detail.aspx",

        },
       
    ]

export const A7595POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7595/A-7595_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7595/A-7595_poi2.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7595/A-7595_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7595/A-7595_poi5.jpg",
        },
          
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7595/A-7595_poi6.jpg",
        },

    ]