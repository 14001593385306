export const A7713 =
    [
        {
            id: 1,
            code: "A-7713-1",
            name: "Держатель для смартфона Медвежонок",
            link: "https://lihitlab-jp.ru/images/A7713/A-7713-1.jpg",
            color: "Коричневый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49993525/detail.aspx",

        },
        {
            id: 5,
            code: "A-7713-5",
            name: "Держатель для смартфона Свинка",
            link: "https://lihitlab-jp.ru/images/A7713/A-7713-5.jpg",
            color: "Розовый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49993529/detail.aspx",
        },
        {
            id: 6,
            code: "A-7713-6",
            name: "Держатель для смартфона Панда",
            link: "https://lihitlab-jp.ru/images/A7713/A-7713-6.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49993530/detail.aspx",
        },
    ]


export const A7713POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7713/A-7713_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7713/A-7713_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7713/A-7713_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7713/A-7713_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7713/A-7713_poi5.jpg",
        },
        
    ]