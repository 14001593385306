export const F7738 =
    [
        {
            id: 2,
            code: "F-7738-3",
            name: "Пенал плоский",
            link: "https://lihitlab-jp.ru/images/F7738/F-7738-33.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49889509/detail.aspx",

        },
        {
            id: 5,
            code: "F-7738-11",
            name: "Пенал плоский",
            link: "https://lihitlab-jp.ru/images/F7738/F-7738-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49889512/detail.aspx",

        },
        {
            id: 6,
            code: "F-7738-19",
            name: "Пенал плоский",
            link: "https://lihitlab-jp.ru/images/F7738/F-7738-19.jpg",
            color: "Мятный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49889513/detail.aspx",

        },
       
       
    ]

export const F7738POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/F7738/F-7738_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/F7738/F-7738_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/F7738/F-7738_poi3.jpg",
        },
     
    ]