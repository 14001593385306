export const PRODUCTLINE =
  [     
    {
      id: 1,
      name: "PuniLubo",
      link: "https://lihitlab-jp.ru/images/img-puni01.jpg",
      cardsRoute: "/catalog/punilabo",
    },

    {
      id: 2,
      name: "ALTNA",
      link: "https://lihitlab-jp.ru/images/altna3_series_top.jpg",
      cardsRoute: "/catalog/altna",
    },
    {
      id: 3,
      name: "SmartFit",
      link: "https://lihitlab-jp.ru/images/smft_img01.jpg",
      cardsRoute: "/catalog/smartfit",
    },
    {
      id: 4,
      name: "ACTACT",
      link: "https://lihitlab-jp.ru/images/img-acta01.jpg",
      cardsRoute: "/catalog/actact",
    },
    {
      id: 5,
      name: "HINEMO",
      link: "https://lihitlab-jp.ru/images/hinemo_ban1.jpg",
      cardsRoute: "/catalog/hinemo",
    },
    {
      id: 6,
      name: "Bloomin",
      link: "https://lihitlab-jp.ru/images/bnr_cat_14-1.jpg",
      cardsRoute: "/catalog/bloomin",
    },
   
   
    

  ]