export const A7733 =
    [
        {
            id: 1,
            code: "A-7733-0",
            name: "Пенал с ремешком",
            link: "https://lihitlab-jp.ru/images/A7733/A-7733-0.jpg",
            color: "Белый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49784141/detail.aspx",

        },
        {
            id: 2,
            code: "A-7733-3",
            name: "Пенал с ремешком",
            link: "https://lihitlab-jp.ru/images/A7733/A-7733-3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49784142/detail.aspx",

        },
        {
            id: 3,
            code: "A-7733-5",
            name: "Пенал с ремешком",
            link: "https://lihitlab-jp.ru/images/A7733/A-7733-5.jpg",
            color: "Желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49784143/detail.aspx",

        },
        {
            id: 4,
            code: "A-7733-10",
            name: "Пенал с ремешком",
            link: "https://lihitlab-jp.ru/images/A7733/A-7733-10.jpg",
            color: "Лавандовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49784144/detail.aspx",

        },
        {
            id: 5,
            code: "A-7733-11",
            name: "Пенал с ремешком",
            link: "https://lihitlab-jp.ru/images/A7733/A-7733-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49784145/detail.aspx",

        },
        {
            id: 6,
            code: "A-7733-19",
            name: "Пенал с ремешком",
            link: "https://lihitlab-jp.ru/images/A7733/A-7733-19.jpg",
            color: "Мятный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49784146/detail.aspx",

        },
       
       
    ]

export const A7733POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7733/A-7733_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7733/A-7733_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7733/A-7733_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7733/A-7733_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7733/A-7733_poi5.jpg",
        },
        

    ]