export const A7910 =
    [
        {
            id: 3,
            code: "A-7910-11",
            name: "Папка-сумка двухсторонняя S",
            link: "https://lihitlab-jp.ru/images/A7910/A-7910_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145545240/detail.aspx",

        },
        {
            id: 4,
            code: "A-7910-16",
            name: "Папка-сумка двухсторонняя S",
            link: "https://lihitlab-jp.ru/images/A7910/A-7910_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145545237/detail.aspx",

        },
        {
            id: 5,
            code: "A-7910-24",
            name: "Папка-сумка двухсторонняя S",
            link: "https://lihitlab-jp.ru/images/A7910/A-7910_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145545242/detail.aspx",

        },
       
    ]

export const A7910POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7910/A-7910_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7910/A-7910_poi2.jpg",
        },
        

    ]