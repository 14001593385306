export const A7800 =
    [
        {
            id: 2,
            code: "A-7800-2",
            name: "Пенал лежащий, большой Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7800/A-7800-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49553230/detail.aspx",
        },
        {
            id: 3,
            code: "A-7800-3",
            name: "Пенал лежащий, большой Черный кот",
            link: "https://lihitlab-jp.ru/images/A7800/A-7800-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49553231/detail.aspx",
        },
        {
            id: 7,
            code: "A-7800-7",
            name: "Пенал лежащий, большой Белый кот",
            link: "https://lihitlab-jp.ru/images/A7800/A-7800-7.jpg",
            color: "белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49553232/detail.aspx",
        },
        {
            id: 16,
            code: "A-7800-16",
            name: "Пенал лежащий, большой Морской Котик",
            link: "https://lihitlab-jp.ru/images/A7800/A-7800-16.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49553233/detail.aspx",
        },
        {
            id: 17,
            code: "A-7800-17",
            name: "Пенал лежащий, большой Цыпленок",
            link: "https://lihitlab-jp.ru/images/A7800/A-7800-17.jpg",
            color: "Желтый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49553234/detail.aspx",
        },
        {
            id: 18,
            code: "A-7800-18",
            name: "Пенал лежащий, большой Песик Мопс",
            link: "https://lihitlab-jp.ru/images/A7800/A-7800-18.jpg",
            color: "белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49553235/detail.aspx",
        },
        
        

    ]


export const A7800POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7800/A-7800_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7800/A-7800_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7800/A-7800_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7800/A-7800_poi4.jpg",
        },
    

    ]