export const A7764 =
    [
        {
            id: 1,
            code: "A-7764-11",
            name: "Пенал-органайзер",
            link: "https://lihitlab-jp.ru/images/A7764/A-7764-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50280401/detail.aspx",

        },
        {
            id: 2,
            code: "A-7764-22",
            name: "Пенал-органайзер",
            link: "https://lihitlab-jp.ru/images/A7764/A-7764-22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50280402/detail.aspx",

        },
        {
            id: 3,
            code: "A-7764-24",
            name: "Пенал-органайзер",
            link: "https://lihitlab-jp.ru/images/A7764/A-7764-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50280403/detail.aspx",

        },

    ]

export const A7764POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7764/A-7764_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7764/A-7764_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7764/A-7764_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7764/A-7764_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7764/A-7764_poi5.jpg",
        },

    ]