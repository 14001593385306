export const A7575 =
    [
        {
            id: 1,
            code: "A-7575-4",
            name: "Папка-сумка для гаджетов А5",
            link: "https://lihitlab-jp.ru/images/A7575/A-7575-4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50212863/detail.aspx",

        },
        {
            id: 2,
            code: "A-7575-11",
            name: "Папка-сумка для гаджетов А5",
            link: "https://lihitlab-jp.ru/images/A7575/A-7575-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50212864/detail.aspx",

        },
        {
            id: 3,
            code: "A-7575-22",
            name: "Папка-сумка для гаджетов А5",
            link: "https://lihitlab-jp.ru/images/A7575/A-7575-22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50212865/detail.aspx",

        },
        {
            id: 4,
            code: "A-7575-24",
            name: "Папка-сумка для гаджетов А5",
            link: "https://lihitlab-jp.ru/images/A7575/A-7575-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50212866/detail.aspx",

        },
        {
            id: 5,
            code: "A-7575-31",
            name: "Папка-сумка для гаджетов А5",
            link: "https://lihitlab-jp.ru/images/A7575/A-7575-31.jpg",
            color: "Камуфляж",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50212867/detail.aspx",

        },
       
    ]

export const A7575POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7575/A-7575_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7575/A-7575_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7575/A-7575_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7575/A-7575_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7575/A-7575_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7575/A-7575_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7575/A-7575_poi7.jpg",
        },

    ]