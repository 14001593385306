export const A7577 =
    [
        {
            id: 1,
            code: "A-7577-4",
            name: "Папка-сумка для гаджетов A4",
            link: "https://lihitlab-jp.ru/images/A7577/A-7577-4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50221394/detail.aspx",

        },
        {
            id: 2,
            code: "A-7577-11",
            name: "Папка-сумка для гаджетов A4",
            link: "https://lihitlab-jp.ru/images/A7577/A-7577-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50221395/detail.aspx",

        },
        {
            id: 3,
            code: "A-7577-22",
            name: "Папка-сумка для гаджетов A4",
            link: "https://lihitlab-jp.ru/images/A7577/A-7577-22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50221396/detail.aspx",

        },
        {
            id: 4,
            code: "A-7577-24",
            name: "Папка-сумка для гаджетов A4",
            link: "https://lihitlab-jp.ru/images/A7577/A-7577-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50221397/detail.aspx",

        },
        {
            id: 5,
            code: "A-7577-31",
            name: "Папка-сумка для гаджетов A4",
            link: "https://lihitlab-jp.ru/images/A7577/A-7577-30.jpg",
            color: "Черный/Белый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/138039578/detail.aspx",

        },
        {
            id: 6,
            code: "A-7577-31",
            name: "Папка-сумка для гаджетов A4",
            link: "https://lihitlab-jp.ru/images/A7577/A-7577-31.jpg",
            color: "Камуфляж",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50221398/detail.aspx",

        },
       
    ]

export const A7577POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7577/A-7577_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7577/A-7577_poi2.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7577/A-7577_poi4.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7577/A-7577_poi7.jpg",
        },

    ]