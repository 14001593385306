export const PASTELLO = [
      {
        id: 94,
        code: "N-1961",
        name: "Блокнот перекидной на пружине формата B7 со сменными листами (40 листов)",
        link: "https://lihitlab-jp.ru/images/N1961/N-1961.jpg",
        cardsRoute: "/catalog/pastello/n1961",
        bestseller: false,
        new: false,
      },
      {
        id: 95,
        code: "N-1908",
        name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
        link: "https://lihitlab-jp.ru/images/N1908/N-1908.jpg",
        cardsRoute: "/catalog/pastello/n1908",
        bestseller: false,
        new: false,
      },
      {
        id: 96,
        code: "N-1969",
        name: "Блокнот перекидной на пружине формата B6 со сменными листами (30 листов)",
        link: "https://lihitlab-jp.ru/images/N1969/N-1969.jpg",
        cardsRoute: "/catalog/pastello/n1969",
        bestseller: false,
        new: false,
      },
      {
        id: 97,
        code: "N-1958",
        name: "Блокнот перекидной на пружине формата A5 со сменными листами (30 листов)",
        link: "https://lihitlab-jp.ru/images/N1958/N-1958.jpg",
        cardsRoute: "/catalog/pastello/n1958",
        bestseller: false,
        new: false,
      },
      {
        id: 98,
        code: "N-1964",
        name: "Блокнот перекидной на пружине формата A6 со сменными листами (30 листов)",
        link: "https://lihitlab-jp.ru/images/N1964/N-1964.jpg",
        cardsRoute: "/catalog/pastello/n1964",
        bestseller: false,
        new: false,
      },
      
    ]