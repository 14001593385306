export const A7651 =
    [
        {
            id: 1,
            code: "A-7651-4",
            name: "Сумка B4 c удлиняющейся ручкой широкая",
            link: "https://lihitlab-jp.ru/images/A7651/A-7651-4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50229362/detail.aspx",

        },
        {
            id: 2,
            code: "A-7651-6",
            name: "Сумка B4 c удлиняющейся ручкой широкая",
            link: "https://lihitlab-jp.ru/images/A7651/A-7651-6.jpg",
            color: "Желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50229363/detail.aspx",

        },
        {
            id: 3,
            code: "A-7651-9",
            name: "Сумка B4 c удлиняющейся ручкой широкая",
            link: "https://lihitlab-jp.ru/images/A7651/A-7651-9.jpg",
            color: "Коричневый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50229364/detail.aspx",

        },
        {
            id: 4,
            code: "A-7651-11",
            name: "Сумка B4 c удлиняющейся ручкой широкая",
            link: "https://lihitlab-jp.ru/images/A7651/A-7651-11N.jpg",
            color: "Коричневый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/156973137/detail.aspx",

        },
        {
            id: 5,
            code: "A-7651-24",
            name: "Сумка B4 c удлиняющейся ручкой широкая",
            link: "https://lihitlab-jp.ru/images/A7651/A-7651-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50229365/detail.aspx",

        },
       
    ]

export const A7651POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7651/A-7651_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7651/A-7651_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7651/A-7651_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7651/A-7651_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7651/A-7651_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7651/A-7651_img1.jpg",
        },
        
        

    ]