export const N2611 =
    [
        {
            id: 1,
            code: "N-2611-5",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (70 листов)",
            link: "https://lihitlab-jp.ru/images/N2611/N-2611_5.jpg",
            color: "Желттый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163767113/detail.aspx",
        },
        {
            id: 2,
            code: "N-2611-7",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (70 листов)",
            link: "https://lihitlab-jp.ru/images/N2611/N-2611_7.jpg",
            color: "Зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163767114/detail.aspx",
        },
        {
            id: 3,
            code: "N-2611-8",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (70 листов)",
            link: "https://lihitlab-jp.ru/images/N2611/N-2611_8.jpg",
            color: "Синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163767115/detail.aspx",
        },
        {
            id: 4,
            code: "N-2611-10",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (70 листов)",
            link: "https://lihitlab-jp.ru/images/N2611/N-2611_10.jpg",
            color: "Фиолетовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163767116/detail.aspx",
        },
        {
            id: 5,
            code: "N-2611-11",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (70 листов)",
            link: "https://lihitlab-jp.ru/images/N2611/N-2611_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163767117/detail.aspx",
        },
        {
            id: 6,
            code: "N-2611-12",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (70 листов)",
            link: "https://lihitlab-jp.ru/images/N2611/N-2611_12.jpg",
            color: "Розовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163767118/detail.aspx",
        },
        {
            id: 7,
            code: "N-2611-16",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (70 листов)",
            link: "https://lihitlab-jp.ru/images/N2611/N-2611_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163767119/detail.aspx",
        },
        
       
    ]

export const N2611POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N2611/N-2611_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/N2611/N-2611_poi2.jpg",
        },          

    ]