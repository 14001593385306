export const HIRAKUNO = [
      {
        id: 84,
        code: "N-1670",
        name: "Блокнот перекидной на пружине формата B7 со сменными листами (50 листов)",
        link: "https://lihitlab-jp.ru/images/N1670/N-1670.jpg",
        cardsRoute: "/catalog/hirakuno/n1670",
        bestseller: false,
        new: false,
      },
      {
        id: 84,
        code: "N-1671",
        name: "Блокнот перекидной на пружине формата A6 со сменными листами (50 листов)",
        link: "https://lihitlab-jp.ru/images/N1671/N-1671.jpg",
        cardsRoute: "/catalog/hirakuno/n1671",
        bestseller: false,
        new: false,
      },
      {
        id: 85,
        code: "N-1672",
        name: "Блокнот перекидной на пружине формата B6 со сменными листами (50 листов)",
        link: "https://lihitlab-jp.ru/images/N1672/N-1672.jpg",
        cardsRoute: "/catalog/hirakuno/n1672",
        bestseller: false,
        new: false,
      },
      {
        id: 86,
        code: "N-1673",
        name: "Блокнот перекидной на пружине формата A5 со сменными листами (50 листов)",
        link: "https://lihitlab-jp.ru/images/N1673/N-1673.jpg",
        cardsRoute: "/catalog/hirakuno/n1673",
        bestseller: false,
        new: false,
      },
      {
        id: 87,
        code: "N-1674",
        name: "Блокнот перекидной на пружине формата B5 со сменными листами (50 листов)",
        link: "https://lihitlab-jp.ru/images/N1674/N-1674.jpg",
        cardsRoute: "/catalog/hirakuno/n1674",
        bestseller: false,
        new: false,
      },
      {
        id: 88,
        code: "N-1677",
        name: "Блокнот перекидной на пружине формата А6/А5Е со сменными листами (10 листов)",
        link: "https://lihitlab-jp.ru/images/N1677/N-1677.jpg",
        cardsRoute: "/catalog/hirakuno/n1677",
        bestseller: false,
        new: false,
      },
      {
        id: 89,
        code: "N-1678",
        name: "Блокнот перекидной на пружине формата B6/B5Е со сменными листами (10 листов)",
        link: "https://lihitlab-jp.ru/images/N1678/N-1678.jpg",
        cardsRoute: "/catalog/hirakuno/n1678",
        bestseller: false,
        new: false,
      },
      
    ]