import './Productline.css';

import footerBaner from '../../images/baner2.png';

import React from 'react';

import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { PRODUCTLINE } from '../../utils/new/productline';

function Productline() {
  const [productline, setProductline] = useState(PRODUCTLINE);

  return (
    <>
      <h2 class='productline-title'>Категории товаров</h2>

      <img src={footerBaner} className='productline__baner' alt='' />

      <div className='productline'>
        {productline.map((card) => (
          <div>
            <h3 className='productline-subtitle'>{card.name}</h3>

            <Link to={card.cardsRoute} className='productline-wrap'>
              <img src={card.link} className='productline-img' alt='' />
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}

export default Productline;
