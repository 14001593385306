export const A7901 =
    [
        {
            id: 1,
            code: "A-7901-3",
            name: "Пенал-косметичка L",
            link: "https://lihitlab-jp.ru/images/A7901/A-7901_3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186095057/detail.aspx",

        },
        {
            id: 2,
            code: "A-7901-5",
            name: "Пенал-косметичка L",
            link: "https://lihitlab-jp.ru/images/A7901/A-7901_5.jpg",
            color: "Желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186095058/detail.aspx",

        },
        {
            id: 3,
            code: "A-7901-11",
            name: "Пенал-косметичка L",
            link: "https://lihitlab-jp.ru/images/A7901/A-7901_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186095059/detail.aspx",

        },
        {
            id: 4,
            code: "A-7901-16",
            name: "Пенал-косметичка L",
            link: "https://lihitlab-jp.ru/images/A7901/A-7901_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186095060/detail.aspx",

        },
        {
            id: 5,
            code: "A-7901-24",
            name: "Пенал-косметичка L",
            link: "https://lihitlab-jp.ru/images/A7901/A-7901_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186095061/detail.aspx",

        },
       
    ]

export const A7901POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7901/A-7901_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7901/A-7901_poi2.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7901/A-7901_ist1.jpg",
        },
        

    ]