export const A7783 =
    [
        {
            id: 2,
            code: "A-7783-2",
            name: "Пенал яйцо, большой Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7783/A-7783-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50117710/detail.aspx",
        },
        {
            id: 3,
            code: "A-7783-3",
            name: "Пенал яйцо, большой Черный кот",
            link: "https://lihitlab-jp.ru/images/A7783/A-7783-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50117711/detail.aspx",
        },
        {
            id: 4,
            code: "A-7783-4",
            name: "Пенал яйцо, большой Cерый кот",
            link: "https://lihitlab-jp.ru/images/A7783/A-7783-4.jpg",
            color: "Серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50117712/detail.aspx",
        },
        {
            id: 7,
            code: "A-7783-7",
            name: "Пенал яйцо, большой Белый кот",
            link: "https://lihitlab-jp.ru/images/A7783/A-7783-7.jpg",
            color: "белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50117713/detail.aspx",
        },
        {
            id: 10,
            code: "A-7783-10",
            name: "Пенал яйцо, большой Пингвин",
            link: "https://lihitlab-jp.ru/images/A7783/A-7783-10.jpg",
            color: "Черно-серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50117714/detail.aspx",
        },

    ]


export const A7783POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7783/A-7783_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7783/A-7783_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7783/A-7783_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7783/A-7783_poi4.jpg",
        },

    ]