export const A7682 =
    [
        {
            id: 1,
            code: "A-7682-3",
            name: "Папка-сумка органайзер вертикальная А5",
            link: "https://lihitlab-jp.ru/images/A7682/A-7682-3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50529629/detail.aspx",

        },
        {
            id: 2,
            code: "A-7682-6",
            name: "Папка-сумка органайзер вертикальная А5",
            link: "https://lihitlab-jp.ru/images/A7682/A-7682-6.jpg",
            color: "Желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50529630/detail.aspx",

        },
        {
            id: 3,
            code: "A-7682-11",
            name: "Папка-сумка органайзер вертикальная А5",
            link: "https://lihitlab-jp.ru/images/A7682/A-7682-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50529631/detail.aspx",

        },
        {
            id: 4,
            code: "A-7682-24",
            name: "Папка-сумка органайзер вертикальная А5",
            link: "https://lihitlab-jp.ru/images/A7682/A-7682-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50529632/detail.aspx",

        },
       
    ]

export const A7682POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7682/A-7682_opn1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7682/A-7682_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7682/A-7682_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7682/A-7682_poi3.jpg",
        }, 
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7682/A-7682_img1.jpg",
        }, 

    ]