export const N1958 =
    [
        {
            id: 1,
            code: "N-1958-2",
            name: "Блокнот перекидной на пружине формата A5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1958/N-1958_2.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164788416/detail.aspx",
        },
        {
            id: 2,
            code: "N-1958-3",
            name: "Блокнот перекидной на пружине формата A5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1958/N-1958_3.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164788417/detail.aspx",
        },
        {
            id: 3,
            code: "N-1958-5",
            name: "Блокнот перекидной на пружине формата A5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1958/N-1958_5.jpg",
            color: "Желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164788418/detail.aspx",
        },
        {
            id: 4,
            code: "N-1958-10",
            name: "Блокнот перекидной на пружине формата A5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1958/N-1958_10.jpg",
            color: "Фиолетовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164788420/detail.aspx",
        },    
        {
            id: 5,
            code: "N-1958-16",
            name: "Блокнот перекидной на пружине формата A5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1958/N-1958_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164788421/detail.aspx",
        },  
        {
            id: 6,
            code: "N-1958-19",
            name: "Блокнот перекидной на пружине формата A5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1958/N-1958_19.jpg",
            color: "Светло-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164788422/detail.aspx",
        },    
       
    ]

export const N1958POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N1958/N-1958_poi1.jpg",
        },      

    ]