import './Catalog.css';

import React from 'react';

import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Routes, Route } from 'react-router-dom';

import Cards from '../Cards/Cards';

function Catalog(props) {
  return (
    <section className='catalog'>
      <ul className='catalog__list'>
        {props.catalogList.map((list) => (
          
            <li className='catalog__list-title'>
              
              <Link to={list.directoryRoute} className='catalog__list-title-link'>{list.directory}</Link>

              {/* <ul className='catalog__list-sublist'>
                {list.subdirectory.map((item) => (
                  <li className='catalog__list-subtitle'>
                    <Link to={item.productRout} className='catalog__list-subtitle-link'>{item.productName}</Link>
                  </li>
                ))}
              </ul> */}



            </li>
          
        ))}
      </ul>

      <Cards
        catalogItem={props.catalogItem}
        title={props.title}
        titlepoi={props.titlepoi}
        imagespoi={props.imagespoi}
        video={props.video}
      />
    </section>
  );
}

export default Catalog;
