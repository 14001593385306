export const A7680 =
    [
        {
            id: 1,
            code: "A-7680-3",
            name: "Папка-сумка органайзер горизонтальная А5",
            link: "https://lihitlab-jp.ru/images/A7680/A-7680-3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50524827/detail.aspx",

        },
        {
            id: 2,
            code: "A-7680-6",
            name: "Папка-сумка органайзер горизонтальная А5",
            link: "https://lihitlab-jp.ru/images/A7680/A-7680-6.jpg",
            color: "Желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50524828/detail.aspx",

        },
        {
            id: 3,
            code: "A-7680-11",
            name: "Папка-сумка органайзер горизонтальная А5",
            link: "https://lihitlab-jp.ru/images/A7680/A-7680-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50524829/detail.aspx",

        },
        {
            id: 4,
            code: "A-7680-24",
            name: "Папка-сумка органайзер горизонтальная А5",
            link: "https://lihitlab-jp.ru/images/A7680/A-7680-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50524830/detail.aspx",

        },
       
    ]

export const A7680POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7680/A-7680_opn1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7680/A-7680_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7680/A-7680_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7680/A-7680_poi3.jpg",
        }, 

    ]