export const A8800 =
    [
        {
            id: 1,
            code: "A-8800-11",
            name: "Папка-сумка складная",
            link: "https://lihitlab-jp.ru/images/A8800/A-8800_1.jpg",
            color: "Белый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/164938147/detail.aspx",

        },
        {
            id: 2,
            code: "A-8800-22",
            name: "Папка-сумка складная",
            link: "https://lihitlab-jp.ru/images/A8800/A-8800_11.jpg",
            color: "Синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/164938148/detail.aspx",

        },
       
    ]

export const A8800POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A8800/A-8800_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A8800/A-8800_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A8800/A-8800_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A8800/A-8800_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A8800/A-8800_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A8800/A-8800_poi6.jpg",
        },
      



    ]