export const A7688 =
    [
        {
            id: 1,
            code: "A-7688-3",
            name: "Пенал-косметичка",
            link: "https://lihitlab-jp.ru/images/A7688/A-7688_3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137626407/detail.aspx",

        },
        {
            id: 2,
            code: "A-7688-6",
            name: "Пенал-косметичка",
            link: "https://lihitlab-jp.ru/images/A7688/A-7688_6.jpg",
            color: "Желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137626413/detail.aspx",

        },
        {
            id: 3,
            code: "A-7688-11",
            name: "Пенал-косметичка",
            link: "https://lihitlab-jp.ru/images/A7688/A-7688_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137626408/detail.aspx",

        },
        {
            id: 4,
            code: "A-7688-24",
            name: "Пенал-косметичка",
            link: "https://lihitlab-jp.ru/images/A7688/A-7688_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137626409/detail.aspx",

        },
       
    ]

export const A7688POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7688/A-7688_opn1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7688/A-7688_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7688/A-7688_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7688/A-7688_poi3.jpg",
        },
        

    ]