export const ALTNA = [
    {
        id: 24,
        code: "A-7760",
        name: "Бизнес сумка",
        link: "https://lihitlab-jp.ru/images/A7760/A-7760.jpg",
        cardsRoute: "/catalog/altna/a7760",
        bestseller: true,
        new: false,
      },
      {
        id: 25,
        code: "A-7750",
        name: "Сумка-органайзер горизонтальная",
        link: "https://lihitlab-jp.ru/images/A7750/A-7750.jpg",
        cardsRoute: "/catalog/altna/a7750",
        bestseller: false,
        new: true,
      },
      {
        id: 26,
        code: "A-7751",
        name: "Сумка-органайзер вертикальная",
        link: "https://lihitlab-jp.ru/images/A7751/A-7751.jpg",
        cardsRoute: "/catalog/altna/a7751",
        bestseller: false,
        new: true,
      },
      {
        id: 27,
        code: "A-7752",
        name: "Сумка-органайзер",
        link: "https://lihitlab-jp.ru/images/A7752/A-7752.jpg",
        cardsRoute: "/catalog/altna/a7752",
        bestseller: false,
        new: true,
      },
      {
        id: 28,
        code: "A-7773",
        name: "Папка-сумка складная",
        link: "https://lihitlab-jp.ru/images/A7773/A-7773.jpg",
        cardsRoute: "/catalog/altna/a7773",
        bestseller: false,
        new: false,
      },
      {
        id: 29,
        code: "A-7791",
        name: "Крючек для сумки",
        link: "https://lihitlab-jp.ru/images/A7791/A-7791.jpg",
        cardsRoute: "/catalog/altna/a7791",
        bestseller: false,
        new: false,
      },
      {
        id: 30,
        code: "А-7767",
        name: "Папка-сумка органайзер M",
        link: "https://lihitlab-jp.ru/images/A7767/A-7767.jpg",
        cardsRoute: "/catalog/altna/a7767",
        bestseller: false,
        new: false,
      },
      {
        id: 31,
        code: "А-7768",
        name: "Папка-сумка органайзер L",
        link: "https://lihitlab-jp.ru/images/A7768/A-7768.jpg",
        cardsRoute: "/catalog/altna/a7768",
        bestseller: false,
        new: false,
      },
      {
        id: 31,
        code: "А-7759",
        name: "Пенал-тубус с магнитной крышкой",
        link: "https://lihitlab-jp.ru/images/A7759/A-7759.jpg",
        cardsRoute: "/catalog/altna/a7759",
        bestseller: false,
        new: false,
      },
      {
        id: 32,
        code: "А-7790",
        name: "Держатель смартфона",
        link: "https://lihitlab-jp.ru/images/A7790/A-7790.jpg",
        cardsRoute: "/catalog/altna/a7790",
        bestseller: false,
        new: false,
      },
      {
        id: 33,
        code: "А-7764",
        name: "Пенал-органайзер",
        link: "https://lihitlab-jp.ru/images/A7764/A-7764.jpg",
        cardsRoute: "/catalog/altna/a7764",
        bestseller: false,
        new: false,
      },
      {
        id: 34,
        code: "А-7765",
        name: "Пенал-органайзер большой",
        link: "https://lihitlab-jp.ru/images/A7765/A-7765.jpg",
        cardsRoute: "/catalog/altna/a7765",
        bestseller: false,
        new: false,
      },
    ]