export const N1908 =
    [
        {
            id: 1,
            code: "N-1908-2",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1908/N-1908_2.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163770408/detail.aspx",
        },
        {
            id: 2,
            code: "N-1908-3",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1908/N-1908_3.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163770410/detail.aspx",
        },
        {
            id: 3,
            code: "N-1908-5",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1908/N-1908_5.jpg",
            color: "Желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163770411/detail.aspx",
        },
        {
            id: 4,
            code: "N-1908-10",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1908/N-1908_10.jpg",
            color: "Фиолетовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163770412/detail.aspx",
        },    
        {
            id: 5,
            code: "N-1908-16",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1908/N-1908_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163770413/detail.aspx",
        },  
        {
            id: 6,
            code: "N-1908-19",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1908/N-1908_19.jpg",
            color: "Светло-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163770415/detail.aspx",
        },    
       
    ]

export const N1908POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N1908/N-1908_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/N1908/N-1908_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/N1908/N-1908_poi3.jpg",
        },
      

    ]