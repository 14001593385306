export const A7730 =
    [
        {
            id: 1,
            code: "A-7730-0",
            name: "Пенал футляр",
            link: "https://lihitlab-jp.ru/images/A7730/A-7730-0.jpg",
            color: "Белый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50335128/detail.aspx",

        },
        {
            id: 2,
            code: "A-7730-3",
            name: "Пенал футляр",
            link: "https://lihitlab-jp.ru/images/A7730/A-7730-3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50335129/detail.aspx",

        },
        {
            id: 3,
            code: "A-7730-5",
            name: "Пенал футляр",
            link: "https://lihitlab-jp.ru/images/A7730/A-7730-5.jpg",
            color: "Желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50335130/detail.aspx",

        },
        {
            id: 4,
            code: "A-7730-10",
            name: "Пенал футляр",
            link: "https://lihitlab-jp.ru/images/A7730/A-7730-10.jpg",
            color: "Лавандовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50335131/detail.aspx",

        },
        {
            id: 5,
            code: "A-7730-11",
            name: "Пенал футляр",
            link: "https://lihitlab-jp.ru/images/A7730/A-7730-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50335132/detail.aspx",

        },
        {
            id: 6,
            code: "A-7730-19",
            name: "Пенал футляр",
            link: "https://lihitlab-jp.ru/images/A7730/A-7730-19.jpg",
            color: "Мятный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50335133/detail.aspx",

        },
       
       
    ]

export const A7730POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7730/A-7730_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7730/A-7730_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7730/A-7730_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7730/A-7730_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7730/A-7730_opn1.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7730/A-7730_img1.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7730/A-7730_img2.jpg",
        },
        

    ]