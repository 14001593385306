export const A7765 =
    [
        {
            id: 1,
            code: "A-7765-11",
            name: "Пенал-органайзер большой",
            link: "https://lihitlab-jp.ru/images/A7765/A-7765-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50288002/detail.aspx",

        },
        {
            id: 2,
            code: "A-7765-22",
            name: "Пенал-органайзер большой",
            link: "https://lihitlab-jp.ru/images/A7765/A-7765-22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50288003/detail.aspx",

        },
        {
            id: 3,
            code: "A-7765-24",
            name: "Пенал-органайзер большой",
            link: "https://lihitlab-jp.ru/images/A7765/A-7765-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50288004/detail.aspx",

        },

    ]

export const A7765POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7765/A-7765_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7765/A-7765_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7765/A-7765_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7765/A-7765_poi4.jpg",
        },

    ]