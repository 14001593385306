export const F7737 =
    [
        {
            id: 2,
            code: "F-7737-3",
            name: "Кошелек",
            link: "https://lihitlab-jp.ru/images/F7737/F-7737-3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49947320/detail.aspx",

        },
        {
            id: 5,
            code: "F-7737-11",
            name: "Кошелек",
            link: "https://lihitlab-jp.ru/images/F7737/F-7737-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49947323/detail.aspx",

        },
        {
            id: 6,
            code: "F-7737-19",
            name: "Кошелек",
            link: "https://lihitlab-jp.ru/images/F7737/F-7737-19.jpg",
            color: "Мятный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49947324/detail.aspx",

        },
       
       
    ]

export const F7737POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/F7737/F-7737_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/F7737/F-7737_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/F7737/F-7737_poi3.jpg",
        },
     
    ]