export const N1647 =
    [
        {
            id: 1,
            code: "N-1647-4",
            name: "Блокнот-органайзер A5",
            link: "https://lihitlab-jp.ru/images/N1647/N-1647-4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50141443/detail.aspx",

        },
        {
            id: 2,
            code: "N-1647-11",
            name: "Блокнот-органайзер A5",
            link: "https://lihitlab-jp.ru/images/N1647/N-1647-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50141444/detail.aspx",

        },
        {
            id: 3,
            code: "N-1647-22",
            name: "Блокнот-органайзер A5",
            link: "https://lihitlab-jp.ru/images/N1647/N-1647-22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50141445/detail.aspx",

        },
        {
            id: 4,
            code: "N-1647-24",
            name: "Блокнот-органайзер A5",
            link: "https://lihitlab-jp.ru/images/N1647/N-1647-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50141446/detail.aspx",

        },
       
    ]

export const N1647POI =
    [
        
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N1647/N-1647_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/N1647/N-1647_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/N1647/N-1647_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/N1647/N-1647_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/N1647/N-1647_img1.jpg",
        },

    ]