export const A7739 =
    [
        {
            id: 2,
            code: "A-7739-8",
            name: "Папка для документов А4",
            link: "https://lihitlab-jp.ru/images/A7739/A-7739_8.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/156983023/detail.aspx",

        },
        {
            id: 3,
            code: "A-7739-10",
            name: "Папка для документов А4",
            link: "https://lihitlab-jp.ru/images/A7739/A-7739_10.jpg",
            color: "Желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/156983024/detail.aspx",

        },
        {
            id: 4,
            code: "A-7739-19",
            name: "Папка для документов А4",
            link: "https://lihitlab-jp.ru/images/A7739/A-7739_19.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/156983025/detail.aspx",

        },
       
    ]

export const A7739POI =
    [
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7739/A-7739_opn1.jpg",
        },
     
    ]