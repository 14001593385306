import './New.css';



import CardImg from '../CardImg/CardImg';



function New(props) {
  return (
    <>
      <h2 class='new-title'>{props.title}</h2>

      <div className='new'>


{props.newsItem.map((card) => (
          <CardImg
            link={card.link}
            name={card.name}
            code={card.code}
            bestseller={card.bestseller}
            new={card.new}
            cardsRoute={card.cardsRoute}
          />
        ))}





      </div>
    </>
  );
}

export default New;
