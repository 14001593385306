export const NEW =
  [
    
   
    {
      id: 66,
      code: "А-7901",
      name: "Пенал-косметичка L",
      link: "https://lihitlab-jp.ru/images/A7901/A-7901.jpg",
      cardsRoute: "/catalog/hinemo/a7901",
      bestseller: false,
      new: true,
    },
    {
      id: 67,
      code: "А-7902",
      name: "Пенал двухсторонний L-образный",
      link: "https://lihitlab-jp.ru/images/A7902/A-7902.jpg",
      cardsRoute: "/catalog/hinemo/a7902",
      bestseller: false,
      new: true,
    },
    {
      id: 48,
      code: "A-7551",
      name: "Пенал-органайзер с одним отделом",
      link: "https://lihitlab-jp.ru/images/A7551/A-7551.jpg",
      cardsRoute: "/catalog/smartfit/a7551",
      bestseller: false,
      new: true,
    },
    {
      id: 63,
      code: "А-7686",
      name: "Пенал-органайзер дорожный",
      link: "https://lihitlab-jp.ru/images/A7686/A-7686.jpg",
      cardsRoute: "/catalog/actact/a7686",
      bestseller: false,
      new: true,
    },
    {
      id: 32,
      code: "A-7574",
      name: "Сумка поясная",
      link: "https://lihitlab-jp.ru/images/A7574/A-7574.jpg",
      cardsRoute: "/catalog/smartfit/a7574",
      bestseller: false,
      new: true,
  },
   
    

  ]