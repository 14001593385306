export const ALCLEA = [
    {
      id: 100,
      code: "A-7940",
      name: "Планшет с антибактериальным покрытием с держателем для ручки",
      link: "https://lihitlab-jp.ru/images/A7940/A-7940.jpg",
      cardsRoute: "/catalog/alclea/a7940",
      bestseller: false,
      new: false,
    },
    {
        id: 101,
        code: "F-7740",
        name: "Папка-планшет с зажимом А4",
        link: "https://lihitlab-jp.ru/images/F7740/F-7740.jpg",
        cardsRoute: "/catalog/alclea/f7740",
        bestseller: false,
        new: false,
      },
      {
        id: 102,
        code: "A-7941",
        name: "Пенал раскладной, с прозрачным карманом",
        link: "https://lihitlab-jp.ru/images/A7941/A-7941.jpg",
        cardsRoute: "/catalog/alclea/a7941",
        bestseller: false,
        new: false,
      },
      {
        id: 103,
        code: "A-7942",
        name: "Пенал-косметичка с прозрачными карманами",
        link: "https://lihitlab-jp.ru/images/A7942/A-7942.jpg",
        cardsRoute: "/catalog/alclea/a7942",
        bestseller: false,
        new: false,
      },
      {
        id: 104,
        code: "HM-511",
        name: "Сумочка для лекарств и пренадлежностей S",
        link: "https://lihitlab-jp.ru/images/HM511/HM511.jpg",
        cardsRoute: "/catalog/alclea/hm511",
        bestseller: false,
        new: false,
      },
      {
        id: 105,
        code: "HM-521",
        name: "Сумочка для документов А6",
        link: "https://lihitlab-jp.ru/images/HM521/HM521.jpg",
        cardsRoute: "/catalog/alclea/hm521",
        bestseller: false,
        new: false,
      },
    
    
  ]