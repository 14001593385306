export const BLOOMIN = [
      {
        id: 71,
        code: "А-7730",
        name: "Пенал футляр",
        link: "https://lihitlab-jp.ru/images/A7730/A-7730.jpg",
        cardsRoute: "/catalog/bloomin/a7730",
        bestseller: false,
        new: false,
      },
      {
        id: 72,
        code: "А-7731",
        name: "Пенал футляр большой",
        link: "https://lihitlab-jp.ru/images/A7731/A-7731.jpg",
        cardsRoute: "/catalog/bloomin/a7731",
        bestseller: false,
        new: false,
      },
      {
        id: 73,
        code: "А-7732",
        name: "Пенал-тубус",
        link: "https://lihitlab-jp.ru/images/A7732/A-7732.jpg",
        cardsRoute: "/catalog/bloomin/a7732",
        bestseller: false,
        new: false,
      },
      {
        id: 74,
        code: "А-7733",
        name: "Пенал с ремешком",
        link: "https://lihitlab-jp.ru/images/A7733/A-7733.jpg",
        cardsRoute: "/catalog/bloomin/a7733",
        bestseller: false,
        new: false,
      },
      {
        id: 75,
        code: "А-7736",
        name: "Косметичка",
        link: "https://lihitlab-jp.ru/images/A7736/A-7736.jpg",
        cardsRoute: "/catalog/bloomin/a7736",
        bestseller: false,
        new: false,
      },
      {
        id: 76,
        code: "А-7737",
        name: "Сумочка-косметичка",
        link: "https://lihitlab-jp.ru/images/A7737/A-7737.jpg",
        cardsRoute: "/catalog/bloomin/a7737",
        bestseller: false,
        new: false,
      },
      {
        id: 77,
        code: "А-7739",
        name: "Папка для документов А4",
        link: "https://lihitlab-jp.ru/images/A7739/A-7739.jpg",
        cardsRoute: "/catalog/bloomin/a7739",
        bestseller: false,
        new: false,
      },
      {
        id: 78,
        code: "F-7737",
        name: "Кошелек",
        link: "https://lihitlab-jp.ru/images/F7737/F-7737.jpg",
        cardsRoute: "/catalog/bloomin/f7737",
        bestseller: false,
        new: false,
      },
      {
        id: 79,
        code: "F-7738",
        name: "Пенал плоский",
        link: "https://lihitlab-jp.ru/images/F7738/F-7738.jpg",
        cardsRoute: "/catalog/bloomin/f7738",
        bestseller: false,
        new: false,
      },
      {
        id: 80,
        code: "F-7739",
        name: "Папка",
        link: "https://lihitlab-jp.ru/images/F7739/F-7739.jpg",
        cardsRoute: "/catalog/bloomin/f7739",
        bestseller: false,
        new: false,
      },
      
    ]