export const A7760 =
    [
        {
            id: 1,
            code: "A-7760-11",
            name: "Бизнес-сумка",
            link: "https://lihitlab-jp.ru/images/A7760/A-7760-11.jpg",
            color: "Темно-синий",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50138939/detail.aspx",

        },
        {
            id: 2,
            code: "A-7760-22",
            name: "Бизнес-сумка",
            link: "https://lihitlab-jp.ru/images/A7760/A-7760-22.jpg",
            color: "Оливковый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50138940/detail.aspx",

        },
        {
            id: 3,
            code: "A-7760-24",
            name: "Бизнес-сумка",
            link: "https://lihitlab-jp.ru/images/A7760/A-7760-24.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50138941/detail.aspx",

        },

    ]

export const A7760POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7760/A-7760_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7760/A-7760_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7760/A-7760_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7760/A-7760_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7760/A-7760_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7760/A-7760_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7760/A-7760_poi7.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7760/A-7760_poi8.jpg",
        },



    ]