export const A7712 =
    [
        {
            id: 1,
            code: "A-7712-1",
            name: "Пенал-тубус малый Медвежонок",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-1.jpg",
            color: "Коричневый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47916415/detail.aspx",

        },
        {
            id: 2,
            code: "A-7712-2",
            name: "Пенал-тубус малый Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47916416/detail.aspx",
        },
        {
            id: 3,
            code: "A-7712-3",
            name: "Пенал-тубус малый Черный кот",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47916417/detail.aspx",
        },
        {
            id: 4,
            code: "A-7712-4",
            name: "Пенал-тубус малый Серый кот",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-4.jpg",
            color: "Серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47916418/detail.aspx",
        },
        {
            id: 5,
            code: "A-7712-5",
            name: "Пенал-тубус малый Свинка",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-5.jpg",
            color: "Розовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47916419/detail.aspx",
        },
        {
            id: 6,
            code: "A-7712-6",
            name: "Пенал-тубус малый Панда",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-6.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47916420/detail.aspx",
        },
        {
            id: 7,
            code: "A-7712-7",
            name: "Пенал-тубус малый Белый кот",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-7.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47916421/detail.aspx",
        },
        {
            id: 8,
            code: "A-7712-8",
            name: "Пенал-тубус малый Бостон терьер",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-8.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47916422/detail.aspx",
        },
        {
            id: 9,
            code: "A-7712-9",
            name: "Пенал-тубус малый Попугай",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-9.jpg",
            color: "желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47916423/detail.aspx",
        },
        {
            id: 10,
            code: "A-7712-10",
            name: "Пенал-тубус малый Пингвин",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-10.jpg",
            color: "черно-серый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47916424/detail.aspx",
        },
        {
            id: 11,
            code: "A-7712-11",
            name: "Пенал-тубус малый Полосатый кот",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-11.jpg",
            color: "бежевый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47916425/detail.aspx",
        },
        {
            id: 12,
            code: "A-7712-12",
            name: "Пенал-тубус малый Кролик",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-12.jpg",
            color: "Розовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47916426/detail.aspx",
        },
        {
            id: 13,
            code: "A-7712-13",
            name: "Пенал-тубус малый Лягушка",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-13.jpg",
            color: "зеленый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47916427/detail.aspx",
        },
        {
            id: 14,
            code: "A-7712-14",
            name: "Пенал-тубус малый Выдра",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-14.jpg",
            color: "коричневый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47916428/detail.aspx",
        },
        {
            id: 15,
            code: "A-7712-15",
            name: "Пенал-тубус малый Мышка",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-15.jpg",
            color: "белый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/47916429/detail.aspx",
        },
        {
            id: 101,
            code: "A-7712-101",
            name: "Пенал-тубус малый Песик Корги",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-101.jpg",
            color: "оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/115921475/detail.aspx",
        },
        {
            id: 102,
            code: "A-7712-102",
            name: "Пенал-тубус малый Американский короткошерстный кот",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-102.jpg",
            color: "серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/115922098/detail.aspx",
        },
        {
            id: 103,
            code: "A-7712-103",
            name: "Пенал-тубус малый Шотландский вислоухий кот",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-103.jpg",
            color: "темно-серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/115922273/detail.aspx",
        },
        {
            id: 104,
            code: "A-7712-104",
            name: "Пенал-тубус малый Цыпленок",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-104.jpg",
            color: "желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/115922441/detail.aspx",
        },
        {
            id: 105,
            code: "A-7712-105",
            name: "Пенал-тубус малый Черный кролик",
            link: "https://lihitlab-jp.ru/images/A7712/A-7712-105.jpg",
            color: "черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137740290/detail.aspx",
        },


    ]


export const A7712POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7712/A-7712_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7712/A-7712_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7712/A-7712_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7712/A-7712_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7712/A-7712_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7712/A-7712_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7712/A-7712_poi7.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7712/A-7712_poi8.jpg",
        },


    ]