export const N1677 =
    [
        {
            id: 1,
            code: "N-1677-0",
            name: "Блокнот перекидной на пружине формата А6/А5Е со сменными листами (10 листов)",
            link: "https://lihitlab-jp.ru/images/N1677/N-1677_0.jpg",
            color: "Белый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186331560/detail.aspx",
        },
        {
            id: 2,
            code: "N-1677-11",
            name: "Блокнот перекидной на пружине формата А6/А5Е со сменными листами (10 листов)",
            link: "https://lihitlab-jp.ru/images/N1677/N-1677_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186331561/detail.aspx",
        },
        {
            id: 3,
            code: "N-1677-24",
            name: "Блокнот перекидной на пружине формата А6/А5Е со сменными листами (10 листов)",
            link: "https://lihitlab-jp.ru/images/N1677/N-1677_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186331562/detail.aspx",
        },
        {
            id: 4,
            code: "N-1677-26",
            name: "Блокнот перекидной на пружине формата А6/А5Е со сменными листами (10 листов)",
            link: "https://lihitlab-jp.ru/images/N1677/N-1677_26.jpg",
            color: "Серый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186331563/detail.aspx",
        },
        
       
    ]

export const N1677POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N1677/N-1677_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/N1677/N-1677_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/N1677/N-1677_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/N1677/N-1677_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/N1677/N-1677_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/N1677/N-1677_poi6.jpg",
        },
            

    ]