export const A7558 =
    [
        {
            id: 1,
            code: "A-7558-11",
            name: "Папка-сумка органайзер А5",
            link: "https://lihitlab-jp.ru/images/A7558/A-7558_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145540458/detail.aspx",

        },
        {
            id: 2,
            code: "A-7558-24",
            name: "Папка-сумка органайзер А5",
            link: "https://lihitlab-jp.ru/images/A7558/A-7558_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145540457/detail.aspx",

        },
       
    ]

export const A7558POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7558/A-7558_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7558/A-7558_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7558/A-7558_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7558/A-7558_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7558/A-7558_poi5.jpg",
        },
               

    ]