export const A7773 =
    [
        {
            id: 1,
            code: "A-7773-3",
            name: "Папка-сумка складная",
            link: "https://lihitlab-jp.ru/images/A7773/A-7773_3.jpg",
            color: "Красный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137744699/detail.aspx",

        },
        {
            id: 2,
            code: "A-7773-11",
            name: "Папка-сумка складная",
            link: "https://lihitlab-jp.ru/images/A7773/A-7773_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137744700/detail.aspx",
        },
        {
            id: 3,
            code: "A-7773-22",
            name: "Папка-сумка складная",
            link: "https://lihitlab-jp.ru/images/A7773/A-7773_22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137744695/detail.aspx",

        },
        {
            id: 4,
            code: "A-7773-24",
            name: "Папка-сумка складная",
            link: "https://lihitlab-jp.ru/images/A7773/A-7773_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137744694/detail.aspx",

        },

    ]

export const A7773POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7773/A-7773_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7773/A-7773_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7773/A-7773_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7773/A-7773_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7773/A-7773_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7773/A-7773_poi6.jpg",
        },

    ]