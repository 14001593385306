import './CatalogImg.css';

import React from 'react';

import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import CardsImg from '../CardsImg/CardsImg';




function CatalogImg(props) {
  return (
    <section className='catalog'>
      <ul className='catalog__list'>
        {props.catalogList.map((list) => (
          <li className='catalog__list-title'>
            <Link to={list.directoryRoute} className='catalog__list-title-link'>
              {list.directory}
            </Link>
            {/* <ul className='catalog__list-sublist'>
              {list.subdirectory.map((item) => (
                <li className='catalog__list-subtitle'>
                  <Link
                    to={item.productRout}
                    className='catalog__list-subtitle-link'>
                    {item.productName}
                  </Link>
                </li>
              ))}
            </ul> */}
          </li>
        ))}
      </ul>

      <CardsImg catalogItem={props.catalogItem} title={props.title} />
    </section>
  );
}

export default CatalogImg;
