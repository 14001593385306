export const A7940 =
    [
        {
            id: 1,
            code: "A-7940-3",
            name: "Планшет с антибактериальным покрытием с держателем для ручки",
            link: "https://lihitlab-jp.ru/images/A7940/A-7940_3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137738618/detail.aspx",
        },
        {
            id: 2,
            code: "A-7940-8",
            name: "Планшет с антибактериальным покрытием с держателем для ручки",
            link: "https://lihitlab-jp.ru/images/A7940/A-7940_8.jpg",
            color: "Синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137738620/detail.aspx",
        },
        {
            id: 3,
            code: "A-7940-19",
            name: "Планшет с антибактериальным покрытием с держателем для ручки",
            link: "https://lihitlab-jp.ru/images/A7940/A-7940_19.jpg",
            color: "Светло-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137738619/detail.aspx",
        },
        {
            id: 4,
            code: "A-7940-24",
            name: "Планшет с антибактериальным покрытием с держателем для ручки",
            link: "https://lihitlab-jp.ru/images/A7940/A-7940_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137738616/detail.aspx",
        },
       
       
    ]

export const A7940POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7940/A-7940_poi1.jpg",
        },     
      

    ]