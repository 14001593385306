export const A7559 =
    [
        {
            id: 1,
            code: "A-7559-11",
            name: "Папка-сумка органайзер А4",
            link: "https://lihitlab-jp.ru/images/A7559/A-7559-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/53886311/detail.aspx",

        },
        {
            id: 2,
            code: "A-7559-24",
            name: "Папка-сумка органайзер А4",
            link: "https://lihitlab-jp.ru/images/A7559/A-7559-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/53886312/detail.aspx",

        },
       
    ]

export const A7559POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7559/A-7559_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7559/A-7559_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7559/A-7559_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7559/A-7559_poi4.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7559/A-7559_poi6.jpg",
        },
               

    ]