export const A7804 =
    [
        {
            id: 2,
            code: "A-7804-2",
            name: "Бейджик Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7804/A-7804-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50058155/detail.aspx",
        },
        {
            id: 3,
            code: "A-7804-3",
            name: "Бейджик Черный кот",
            link: "https://lihitlab-jp.ru/images/A7804/A-7804-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50058156/detail.aspx",
        },
        {
            id: 7,
            code: "A-7804-7",
            name: "Бейджик Белый кот",
            link: "https://lihitlab-jp.ru/images/A7804/A-7804-7.jpg",
            color: "белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50058157/detail.aspx",
        },
        {
            id: 16,
            code: "A-7804-16",
            name: "Бейджик Морской Котик",
            link: "https://lihitlab-jp.ru/images/A7804/A-7804-16.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50058158/detail.aspx",
        },
        {
            id: 17,
            code: "A-7804-17",
            name: "Бейджик Цыпленок",
            link: "https://lihitlab-jp.ru/images/A7804/A-7804-17.jpg",
            color: "Желтый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50058159/detail.aspx",
        },
        {
            id: 18,
            code: "A-7804-18",
            name: "Бейджик Песик Мопс",
            link: "https://lihitlab-jp.ru/images/A7804/A-7804-18.jpg",
            color: "белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50058160/detail.aspx",
        },
        
        

    ]


export const A7804POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7804/A-7804_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7804/A-7804_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7804/A-7804_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7804/A-7804_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7804/A-7804_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7804/A-7804_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7804/A-7804_poi7.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7804/A-7804_poi8.jpg",
        },
        {
            id: 9,
            link: "https://lihitlab-jp.ru/images/A7804/A-7804_poi9.jpg",
        },
        {
            id: 10,
            link: "https://lihitlab-jp.ru/images/A7804/A-7804_poi10.jpg",
        },
    

    ]