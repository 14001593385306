export const A7551 =
    [
        {
            id: 1,
            code: "A-7551-4",
            name: "Пенал-органайзер с одним отделом",
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185749723/detail.aspx",

        },
        {
            id: 2,
            code: "A-7551-6",
            name: "Пенал-органайзер с одним отделом",
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_6.jpg",
            color: "Желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185749724/detail.aspx",

        },
        {
            id: 3,
            code: "A-7551-9",
            name: "Пенал-органайзер с одним отделом",
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_9.jpg",
            color: "Коричневый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185749725/detail.aspx",

        },
        {
            id: 4,
            code: "A-7551-24",
            name: "Пенал-органайзер с одним отделом",
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185749726/detail.aspx",

        },
       
    ]

export const A7551POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_poi7.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_poi8.jpg",
        },
        {
            id: 9,
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_poi9.jpg",
        },
        {
            id: 10,
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_poi10.jpg",
        },
        {
            id: 11,
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_poi11.jpg",
        },
        {
            id: 12,
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_poi12.jpg",
        },
        {
            id: 13,
            link: "https://lihitlab-jp.ru/images/A7551/A-7551_poi13.jpg",
        },
      
        

    ]