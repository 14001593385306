export const N3104 =
    [
        {
            id: 1,
            code: "N-3104-5",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N3104/N-3104_5.jpg",
            color: "Желттый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164877638/detail.aspx",
        },
        {
            id: 2,
            code: "N-3104-8",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N3104/N-3104_8.jpg",
            color: "Голубой",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164877639/detail.aspx",
        },
        {
            id: 3,
            code: "N-3104-10",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N3104/N-3104_10.jpg",
            color: "Фиолетовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164877640/detail.aspx",
        },
        {
            id: 4,
            code: "N-3104-12",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N3104/N-3104_12.jpg",
            color: "Розовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164877641/detail.aspx",
        },        
       
    ]

export const N3104POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N3104/N-3104_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/N3104/N-3104_poi2.jpg",
        },      

    ]