export const A7728 =
    [
        {
            id: 2,
            code: "A-7728-2",
            name: "Очиститель экрана Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7728/A-7728-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50132907/detail.aspx",
        },
        {
            id: 3,
            code: "A-7728-3",
            name: "Очиститель экрана Черный кот",
            link: "https://lihitlab-jp.ru/images/A7728/A-7728-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50132908/detail.aspx",
        },
        {
            id: 7,
            code: "A-7728-7",
            name: "Очиститель экрана Белый кот",
            link: "https://lihitlab-jp.ru/images/A7728/A-7728-7.jpg",
            color: "белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50132909/detail.aspx",
        },
        {
            id: 5,
            code: "A-7728-8",
            name: "Очиститель экрана Бостон терьер",
            link: "https://lihitlab-jp.ru/images/A7728/A-7728-8.jpg",
            color: "черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50132909/detail.aspx",
            
        },
        {
            id: 9,
            code: "A-7728-9",
            name: "Очиститель экрана Попугай",
            link: "https://lihitlab-jp.ru/images/A7728/A-7728-9.jpg",
            color: "Желто-зеленый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50132911/detail.aspx",
        },
        {
            id: 10,
            code: "A-7728-10",
            name: "Очиститель экрана Пингвин",
            link: "https://lihitlab-jp.ru/images/A7728/A-7728-10.jpg",
            color: "Черно-серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50132912/detail.aspx",
        },
    ]


export const A7728POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7728/A-7728_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7728/A-7728_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7728/A-7728_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7728/A-7728_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7728/A-7728_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7728/A-7728_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7728/A-7728_poi7.jpg",
        },
      

    ]