export const A7581 =
    [
        
        {
            id: 2,
            code: "A-7581-11",
            name: "Сумка c увеличивающейся толщиной B4",
            link: "https://lihitlab-jp.ru/images/A7581/A-7581_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145520053/detail.aspx",

        },
        {
            id: 4,
            code: "A-7581-24",
            name: "Сумка c увеличивающейся толщиной B4",
            link: "https://lihitlab-jp.ru/images/A7581/A-7581_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145520052/detail.aspx",

        },
       
    ]

export const A7581POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7581/A-7581_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7581/A-7581_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7581/A-7581_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7581/A-7581_poi4.jpg",
        },
                     

    ]