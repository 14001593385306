export const A7732 =
    [
        {
            id: 2,
            code: "A-7732-3",
            name: "Пенал- тубус",
            link: "https://lihitlab-jp.ru/images/A7732/A-7732-3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49782235/detail.aspx",

        },
        {
            id: 3,
            code: "A-7732-5",
            name: "Пенал- тубус",
            link: "https://lihitlab-jp.ru/images/A7732/A-7732-5.jpg",
            color: "Желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49782236/detail.aspx",

        },
        {
            id: 4,
            code: "A-7732-10",
            name: "Пенал- тубус",
            link: "https://lihitlab-jp.ru/images/A7732/A-7732-10.jpg",
            color: "Лавандовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49782237/detail.aspx",

        },
        {
            id: 5,
            code: "A-7732-11",
            name: "Пенал- тубус",
            link: "https://lihitlab-jp.ru/images/A7732/A-7732-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49782238/detail.aspx",

        },
        {
            id: 6,
            code: "A-7732-19",
            name: "Пенал- тубус",
            link: "https://lihitlab-jp.ru/images/A7732/A-7732-19.jpg",
            color: "Мятный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49782239/detail.aspx",

        },
       
       
    ]

export const A7732POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7732/A-7732_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7732/A-7732_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7732/A-7732_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7732/A-7732_poi4.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7732/A-7732_poi5.jpg",
        },
        
        

    ]