export const A7650 =
    [
        {
            id: 1,
            code: "A-7650-4",
            name: "Сумка B4 c удлиняющейся ручкой",
            link: "https://lihitlab-jp.ru/images/A7650/A-7650-4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50227433/detail.aspx",

        },
        {
            id: 2,
            code: "A-7650-6",
            name: "Сумка B4 c удлиняющейся ручкой",
            link: "https://lihitlab-jp.ru/images/A7650/A-7650-6.jpg",
            color: "Желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50227434/detail.aspx",

        },
        {
            id: 3,
            code: "A-7650-9",
            name: "Сумка B4 c удлиняющейся ручкой",
            link: "https://lihitlab-jp.ru/images/A7650/A-7650-9.jpg",
            color: "Коричневый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50227435/detail.aspx",

        },
        {
            id: 4,
            code: "A-7650-24",
            name: "Сумка B4 c удлиняющейся ручкой",
            link: "https://lihitlab-jp.ru/images/A7650/A-7650-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50227436/detail.aspx",

        },
       
    ]

export const A7650POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7650/A-7650_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7650/A-7650_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7650/A-7650_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7650/A-7650_img1.jpg",
        },
        

    ]