export const PUNILABO =
  [
    {
      id: 1,
      code: "A-7712",
      name: "Пенал-тубус малый",
      link: "https://lihitlab-jp.ru/images/A7712/A-7712.jpg",
      cardsRoute: "/catalog/punilabo/a7712",
      bestseller: true,
      new: false,
    },
    {
      id: 2,
      code: "A-7713",
      name: "Держатель для смартфона",
      link: "https://lihitlab-jp.ru/images/A7713/A-7713.jpg",
      cardsRoute: "/catalog/punilabo/a7713",
      bestseller: true,
      new: false,
    },
    {
      id: 3,
      code: "A-7714",
      name: "Пенал-тубус большой",
      link: "https://lihitlab-jp.ru/images/A7714/A-7714.jpg",
      cardsRoute: "/catalog/punilabo/a7714",
      bestseller: true,
      new: false,
    },
    {
      id: 4,
      code: "A-7716",
      name: "Пенал-косметичка",
      link: "https://lihitlab-jp.ru/images/A7716/A-7716.jpg",
      cardsRoute: "/catalog/punilabo/a7716",
      bestseller: true,
      new: false,
    },
    {
      id: 5,
      code: "A-7717",
      name: "Пенал узкий, длинный",
      link: "https://lihitlab-jp.ru/images/A7717/A-7717.jpg",
      cardsRoute: "/catalog/punilabo/a7717",
      bestseller: true,
      new: false,
    },

    {
      id: 6,
      code: "A-7718",
      name: "Брелок с звуковой сигнализацией",
      link: "https://lihitlab-jp.ru/images/A7718/A-7718.jpg",
      cardsRoute: "/catalog/punilabo/a7718",
      bestseller: true,
      new: false,
    },
    {
      id: 7,
      code: "A-7720",
      name: "Пенал с ремешком",
      link: "https://lihitlab-jp.ru/images/A7720/A-7720.jpg",
      cardsRoute: "/catalog/punilabo/a7720",
      bestseller: true,
      new: false,
    },
    {
      id: 8,
      code: "A-7721",
      name: "Магнитная клипса",
      link: "https://lihitlab-jp.ru/images/A7721/A-7721.jpg",
      cardsRoute: "/catalog/punilabo/a7721",
      bestseller: false,
      new: false,
    },
    {
      id: 9,
      code: "A-7722",
      name: "Стягиватель",
      link: "https://lihitlab-jp.ru/images/A7722/A-7722.jpg",
      cardsRoute: "/catalog/punilabo/a7722",
      bestseller: false,
      new: false,
    },
    {
      id: 10,
      code: "A-7723",
      name: "Кейс для записей",
      link: "https://lihitlab-jp.ru/images/A7723/A-7723.jpg",
      cardsRoute: "/catalog/punilabo/a7723",
      bestseller: false,
      new: false,
    },
    {
      id: 11,
      code: "A-7724",
      name: "Кейс для записей, большой",
      link: "https://lihitlab-jp.ru/images/A7724/A-7724.jpg",
      cardsRoute: "/catalog/punilabo/a7724",
      bestseller: false,
      new: false,
    },
    {
      id: 12,
      code: "A-7725",
      name: "Магнит канцелярский",
      link: "https://lihitlab-jp.ru/images/A7725/A-7725.jpg",
      cardsRoute: "/catalog/punilabo/a7725",
      bestseller: false,
      new: false,
    },
    {
      id: 13,
      code: "A-7727",
      name: "Держатель для кабеля телефона",
      link: "https://lihitlab-jp.ru/images/A7727/A-7727.jpg",
      cardsRoute: "/catalog/punilabo/a7727",
      bestseller: false,
      new: false,
    },
    {
      id: 14,
      code: "A-7728",
      name: "Очиститель экрана",
      link: "https://lihitlab-jp.ru/images/A7728/A-7728.jpg",
      cardsRoute: "/catalog/punilabo/a7728",
      bestseller: false,
      new: false,
    },
    {
      id: 15,
      code: "A-7780",
      name: "Пенал лежащий, мини",
      link: "https://lihitlab-jp.ru/images/A7780/A-7780.jpg",
      cardsRoute: "/catalog/punilabo/a7780",
      bestseller: true,
      new: false,
    },
    {
      id: 16,
      code: "A-7781",
      name: "Пенал лежащий, длинный",
      link: "https://lihitlab-jp.ru/images/A7781/A-7781.jpg",
      cardsRoute: "/catalog/punilabo/a7781",
      bestseller: true,
      new: false,
    },
    {
      id: 17,
      code: "A-7782",
      name: "Пенал яйцо",
      link: "https://lihitlab-jp.ru/images/A7782/A-7782.jpg",
      cardsRoute: "/catalog/punilabo/a7782",
      bestseller: true,
      new: false,
    },
    {
      id: 18,
      code: "A-7783",
      name: "Пенал яйцо, большой",
      link: "https://lihitlab-jp.ru/images/A7783/A-7783.jpg",
      cardsRoute: "/catalog/punilabo/a7783",
      bestseller: true,
      new: false,
    },
    {
      id: 19,
      code: "A-7784",
      name: "Кошелек",
      link: "https://lihitlab-jp.ru/images/A7784/A-7784.jpg",
      cardsRoute: "/catalog/punilabo/a7784",
      bestseller: true,
      new: false,
    },
    {
      id: 20,
      code: "A-7785",
      name: "Ключница",
      link: "https://lihitlab-jp.ru/images/A7785/A-7785.jpg",
      cardsRoute: "/catalog/punilabo/a7785",
      bestseller: true,
      new: false,
    },
    {
      id: 21,
      code: "A-7800",
      name: "Пенал лежащий, большой",
      link: "https://lihitlab-jp.ru/images/A7800/A-7800.jpg",
      cardsRoute: "/catalog/punilabo/a7800",
      bestseller: true,
      new: false,
    },
    {
      id: 22,
      code: "A-7803",
      name: "Ножницы",
      link: "https://lihitlab-jp.ru/images/A7803/A-7803.jpg",
      cardsRoute: "/catalog/punilabo/a7803",
      bestseller: true,
      new: false,
    },
    {
      id: 23,
      code: "A-7804",
      name: "Бейджик",
      link: "https://lihitlab-jp.ru/images/A7804/A-7804.jpg",
      cardsRoute: "/catalog/punilabo/a7804",
      bestseller: true,
      new: false,
    },

  ]