export const HM511 =
    [
        {
            id: 1,
            code: "HM-511-blue",
            name: "Сумочка для лекарств и пренадлежностей S",
            link: "https://lihitlab-jp.ru/images/HM511/HM511_blue.jpg",
            color: "Синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/146189762/detail.aspx",
        },
        {
            id: 1,
            code: "HM-511-red",
            name: "Сумочка для лекарств и пренадлежностей S",
            link: "https://lihitlab-jp.ru/images/HM511/HM511_red.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/146189763/detail.aspx",
        },
      
       
       
    ]

export const HM511POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/HM511/HM511_poi1.jpg",
        },     
       

    ]