export const A7750 =
    [
        {
            id: 1,
            code: "A-7750-11",
            name: "Сумка-органайзер горизонтальная",
            link: "https://lihitlab-jp.ru/images/A7750/A-7750_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137183825/detail.aspx",

        },
        {
            id: 2,
            code: "A-7750-22",
            name: "Сумка-органайзер горизонтальная",
            link: "https://lihitlab-jp.ru/images/A7750/A-7750_22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137183830/detail.aspx",

        },
        {
            id: 3,
            code: "A-7750-24",
            name: "Сумка-органайзер горизонтальная",
            link: "https://lihitlab-jp.ru/images/A7750/A-7750_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137183833/detail.aspx",

        },

    ]

export const A7750POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7750/A-7750_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7750/A-7750_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7750/A-7750_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7750/A-7750_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7750/A-7750_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7750/A-7750_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7750/A-7750_poi7.jpg",
        },
       



    ]