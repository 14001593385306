import './Brend.css';

import presentation from '../../images/PRESENTATION.jpg';
import presentation1 from '../../images/PRESENTATION1.jpg';
import presentation2 from '../../images/PRESENTATION2.jpg';
import presentation3 from '../../images/PRESENTATION3.jpg';
import presentation4 from '../../images/PRESENTATION4.jpg';
import presentation5 from '../../images/PRESENTATION5.jpg';
import presentation6 from '../../images/PRESENTATION6.jpg';
import presentation7 from '../../images/PRESENTATION7.jpg';
import presentation8 from '../../images/PRESENTATION8.jpg';
import presentation9 from '../../images/PRESENTATION9.jpg';
import presentation10 from '../../images/PRESENTATION10.jpg';
import presentation11 from '../../images/PRESENTATION11.jpg';
import presentation12 from '../../images/PRESENTATION12.jpg';
import presentation13 from '../../images/PRESENTATION13.jpg';
import presentation14 from '../../images/PRESENTATION14.jpg';

function Brend() {
  return (
    <div className='brend'>
      

      


      

      <img src={presentation5} className='brend-img' alt='' />

      <img src={presentation6} className='brend-img' alt='' />

      <img src={presentation7} className='brend-img' alt='' />

      <img src={presentation8} className='brend-img' alt='' />
           

     

      <img src={presentation9} className='brend-img' alt='' />

      <img src={presentation10} className='brend-img' alt='' />

      <img src={presentation} className='brend-img' alt='' />

      <img src={presentation11} className='brend-img' alt='' />

      <img src={presentation12} className='brend-img' alt='' />

      <img src={presentation13} className='brend-img' alt='' />

      <img src={presentation14} className='brend-img' alt='' />

      
    </div>
  );
}

export default Brend;
