export const A7694 =
    [
        {
            id: 1,
            code: "A-7694-3",
            name: "Пенал-тубус овальный",
            link: "https://lihitlab-jp.ru/images/A7694/A-7694-3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49785459/detail.aspx",

        },
        {
            id: 2,
            code: "A-7694-4",
            name: "Пенал-тубус овальный",
            link: "https://lihitlab-jp.ru/images/A7694/A-7694-4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49785460/detail.aspx",

        },
        {
            id: 3,
            code: "A-7694-6",
            name: "Пенал-тубус овальный",
            link: "https://lihitlab-jp.ru/images/A7694/A-7694-6.jpg",
            color: "Желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49785461/detail.aspx",

        },
        {
            id: 4,
            code: "A-7694-11",
            name: "Пенал-тубус овальный",
            link: "https://lihitlab-jp.ru/images/A7694/A-7694-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49785462/detail.aspx",

        },
        {
            id: 5,
            code: "A-7694-14",
            name: "Пенал-тубус овальный",
            link: "https://lihitlab-jp.ru/images/A7694/A-7694-14.jpg",
            color: "Бирюзовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49785463/detail.aspx",

        },
        {
            id: 6,
            code: "A-7694-24",
            name: "Пенал-тубус овальный",
            link: "https://lihitlab-jp.ru/images/A7694/A-7694-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49785464/detail.aspx",

        },
       
    ]

export const A7694POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7694/A-7694_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7694/A-7694_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7694/A-7694_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7694/A-7694_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7694/A-7694_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7694/A-7694_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7694/A-7694_poi7.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7694/A-7694_img1.jpg",
        },        

    ]