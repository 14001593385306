export const A7557 =
    [
        {
            id: 4,
            code: "A-7557-24",
            name: "Пенал-органайзер с двумя отделами узкий",
            link: "https://lihitlab-jp.ru/images/A7557/A-7557.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185933075/detail.aspx",

        },
       
    ]

export const A7557POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7557/A-7557_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7557/A-7557_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7557/A-7557_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7557/A-7557_poi4.jpg",
        },
               

    ]