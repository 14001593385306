export const A7736 =
    [
        {
            id: 1,
            code: "A-7736-0",
            name: "Косметичка",
            link: "https://lihitlab-jp.ru/images/A7736/A-7736-0.jpg",
            color: "Белый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/116588374/detail.aspx",

        },
        {
            id: 2,
            code: "A-7736-3",
            name: "Косметичка",
            link: "https://lihitlab-jp.ru/images/A7736/A-7736-3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/53891267/detail.aspx",

        },
        {
            id: 3,
            code: "A-7736-5",
            name: "Косметичка",
            link: "https://lihitlab-jp.ru/images/A7736/A-7736-5.jpg",
            color: "Желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/116588946/detail.aspx",

        },
        {
            id: 5,
            code: "A-7736-11",
            name: "Косметичка",
            link: "https://lihitlab-jp.ru/images/A7736/A-7736-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/53891268/detail.aspx",

        },
        {
            id: 6,
            code: "A-7736-19",
            name: "Косметичка",
            link: "https://lihitlab-jp.ru/images/A7736/A-7736-19.jpg",
            color: "Мятный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/53891269/detail.aspx",

        },
       
       
    ]

export const A7736POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7736/A-7736_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7736/A-7736_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7736/A-7736_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7736/A-7736_poi4.jpg",
        },        

    ]