export const A7723 =
    [
        {
            id: 1,
            code: "A-7723-1",
            name: "Блок для записей Медвежонок",
            link: "https://lihitlab-jp.ru/images/A7723/A-7723_1.jpg",
            color: "Коричневый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50569217/detail.aspx",

        },
        {
            id: 2,
            code: "A-7723-2",
            name: "Блок для записей Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7723/A-7723_2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50569218/detail.aspx",

        },
        {
            id: 3,
            code: "A-7723-3",
            name: "Блок для записей Черный кот",
            link: "https://lihitlab-jp.ru/images/A7723/A-7723_3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50569219/detail.aspx",

        },
        {
            id: 4,
            code: "A-7723-4",
            name: "Блок для записей Серый кот",
            link: "https://lihitlab-jp.ru/images/A7723/A-7723_4.jpg",
            color: "Серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50569220/detail.aspx",

        },
        {
            id: 5,
            code: "A-7723-5",
            name: "Блок для записей Свинка",
            link: "https://lihitlab-jp.ru/images/A7723/A-7723_5.jpg",
            color: "Розовый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50569221/detail.aspx",

        },
        {
            id: 6,
            code: "A-7723-6",
            name: "Блок для записей Панда",
            link: "https://lihitlab-jp.ru/images/A7723/A-7723_6.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50569222/detail.aspx",

        },
    ]


export const A7723POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7723/A-7723_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7723/A-7723_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7723/A-7723_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7723/A-7723_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7723/A-7723_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7723/A-7723_poi6.jpg",
        },

    ]