export const N1678 =
    [
        {
            id: 1,
            code: "N-1678-0",
            name: "Блокнот перекидной на пружине формата B6/B5Е со сменными листами (10 листов)",
            link: "https://lihitlab-jp.ru/images/N1678/N-1678_0.jpg",
            color: "Белый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186395450/detail.aspx",
        },
        {
            id: 2,
            code: "N-1678-11",
            name: "Блокнот перекидной на пружине формата B6/B5Е со сменными листами (10 листов)",
            link: "https://lihitlab-jp.ru/images/N1678/N-1678_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186395453/detail.aspx",
        },
        {
            id: 3,
            code: "N-1678-24",
            name: "Блокнот перекидной на пружине формата B6/B5Е со сменными листами (10 листов)",
            link: "https://lihitlab-jp.ru/images/N1678/N-1678_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186395455/detail.aspx",
        },
        {
            id: 4,
            code: "N-1678-26",
            name: "Блокнот перекидной на пружине формата B6/B5Е со сменными листами (10 листов)",
            link: "https://lihitlab-jp.ru/images/N1678/N-1678_26.jpg",
            color: "Серый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186395457/detail.aspx",
        },
        
       
    ]

export const N1678POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N1678/N-1678_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/N1678/N-1678_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/N1678/N-1678_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/N1678/N-1678_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/N1678/N-1678_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/N1678/N-1678_poi6.jpg",
        },
            

    ]