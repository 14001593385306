export const N1627 =
    [
        {
            id: 1,
            code: "N-1627-4",
            name: "Блокнот-органайзер B5",
            link: "https://lihitlab-jp.ru/images/N1627/N-1627-4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50141031/detail.aspx",

        },
        {
            id: 2,
            code: "N-1627-11",
            name: "Блокнот-органайзер B5",
            link: "https://lihitlab-jp.ru/images/N1627/N-1627-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50141032/detail.aspx",

        },
        {
            id: 3,
            code: "N-1627-22",
            name: "Блокнот-органайзер B5",
            link: "https://lihitlab-jp.ru/images/N1627/N-1627-22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50141033/detail.aspx",

        },
        {
            id: 4,
            code: "N-1627-24",
            name: "Блокнот-органайзер B5",
            link: "https://lihitlab-jp.ru/images/N1627/N-1627-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50141034/detail.aspx",

        },
       
    ]

export const N1627POI =
    [
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/N1627/N-1627_opn1.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N1627/N-1627_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/N1627/N-1627_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/N1627/N-1627_poi3.jpg",
        },
       

    ]