export const A7785 =
    [
        {
            id: 2,
            code: "A-7785-2",
            name: "Ключница Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7785/A-7785-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50121281/detail.aspx",
        },
        {
            id: 3,
            code: "A-7785-3",
            name: "Ключница Черный кот",
            link: "https://lihitlab-jp.ru/images/A7785/A-7785-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50121282/detail.aspx",
        },
        {
            id: 4,
            code: "A-7785-4",
            name: "Ключница Cерый кот",
            link: "https://lihitlab-jp.ru/images/A7785/A-7785-4.jpg",
            color: "Серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50121283/detail.aspx",
        },
        {
            id: 7,
            code: "A-7785-7",
            name: "Ключница Белый кот",
            link: "https://lihitlab-jp.ru/images/A7785/A-7785-7.jpg",
            color: "белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50121284/detail.aspx",
        },
        {
            id: 10,
            code: "A-7785-10",
            name: "Ключница Пингвин",
            link: "https://lihitlab-jp.ru/images/A7785/A-7785-10.jpg",
            color: "Черно-серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50121285/detail.aspx",
        },

    ]


export const A7785POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7785/A-7785_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7785/A-7785_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7785/A-7785_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7785/A-7785_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7785/A-7785_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7785/A-7785_img2.jpg",
        },

    ]