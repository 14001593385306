export const N2658 =
    [
        {
            id: 1,
            code: "N-2658-5",
            name: "Блокнот перекидной на пружине формата А5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N2658/N-2658_5.jpg",
            color: "Желттый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163762867/detail.aspx",
        },
        {
            id: 2,
            code: "N-2658-7",
            name: "Блокнот перекидной на пружине формата А5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N2658/N-2658_7.jpg",
            color: "Зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163762869/detail.aspx",
        },
        {
            id: 3,
            code: "N-2658-8",
            name: "Блокнот перекидной на пружине формата А5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N2658/N-2658_8.jpg",
            color: "Синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163762871/detail.aspx",
        },
        {
            id: 4,
            code: "N-2658-10",
            name: "Блокнот перекидной на пружине формата А5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N2658/N-2658_10.jpg",
            color: "Фиолетовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163762873/detail.aspx",
        },
        {
            id: 5,
            code: "N-2658-11",
            name: "Блокнот перекидной на пружине формата А5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N2658/N-2658_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163762874/detail.aspx",
        },
        {
            id: 6,
            code: "N-2658-12",
            name: "Блокнот перекидной на пружине формата А5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N2658/N-2658_12.jpg",
            color: "Розовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163762876/detail.aspx",
        },
        {
            id: 7,
            code: "N-2658-16",
            name: "Блокнот перекидной на пружине формата А5 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N2658/N-2658_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163762877/detail.aspx",
        },
        
       
    ]

export const N2658POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N2658/N-2658_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/N2658/N-2658_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/N2658/N-2658_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/N2658/N-2658_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/N2658/N-2658_poi5.jpg",
        },
            

    ]