export const A7552 =
    [
        {
            id: 1,
            code: "A-7552-4",
            name: "Пенал-органайзер с одним отделом узкий",
            link: "https://lihitlab-jp.ru/images/A7552/A-7552_4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185753017/detail.aspx",

        },
        {
            id: 2,
            code: "A-7552-6",
            name: "Пенал-органайзер с одним отделом узкий",
            link: "https://lihitlab-jp.ru/images/A7552/A-7552_6.jpg",
            color: "Желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185753018/detail.aspx",

        },
        {
            id: 4,
            code: "A-7552-24",
            name: "Пенал-органайзер с одним отделом узкий",
            link: "https://lihitlab-jp.ru/images/A7552/A-7552_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185753019/detail.aspx",

        },
       
    ]

export const A7552POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7552/A-7552_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7552/A-7552_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7552/A-7552_poi3.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7552/A-7552_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7552/A-7552_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7552/A-7552_poi7.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7552/A-7552_poi8.jpg",
        },
        {
            id: 9,
            link: "https://lihitlab-jp.ru/images/A7552/A-7552_poi9.jpg",
        },
        {
            id: 10,
            link: "https://lihitlab-jp.ru/images/A7552/A-7552_poi10.jpg",
        },
      
      
        

    ]