export const A7670 =
    [
        {
            id: 1,
            code: "A-7670-3",
            name: "Сумка-рюкзак горизонтальная",
            link: "https://lihitlab-jp.ru/images/A7670/A-7670-3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50301463/detail.aspx",

        },
        {
            id: 3,
            code: "A-7670-6",
            name: "Сумка-рюкзак горизонтальная",
            link: "https://lihitlab-jp.ru/images/A7670/A-7670-6.jpg",
            color: "Желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50301464/detail.aspx",

        },
        {
            id: 4,
            code: "A-7670-11",
            name: "Сумка-рюкзак горизонтальная",
            link: "https://lihitlab-jp.ru/images/A7670/A-7670-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50301465/detail.aspx",

        },
        {
            id: 6,
            code: "A-7670-24",
            name: "Сумка-рюкзак горизонтальная",
            link: "https://lihitlab-jp.ru/images/A7670/A-7670-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50301466/detail.aspx",

        },
       
    ]

export const A7670POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7670/A-7670_poi1.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7670/A-7670_opn1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7670/A-7670_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7670/A-7670_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7670/A-7670_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7670/A-7670_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7670/A-7670_poi6.jpg",
        },




        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7670/A-7670_img1.jpg",
        },        

    ]