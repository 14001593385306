export const A7941 =
    [
        {
            id: 1,
            code: "A-7941-1",
            name: "Пенал раскладной, с прозрачным карманом",
            link: "https://lihitlab-jp.ru/images/A7941/A-7941_1.jpg",
            color: "Прозрачный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137637942/detail.aspx",
        },
        {
            id: 1,
            code: "A-7941-3",
            name: "Пенал раскладной, с прозрачным карманом",
            link: "https://lihitlab-jp.ru/images/A7941/A-7941_3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137637944/detail.aspx",
        },
        {
            id: 2,
            code: "A-7941-8",
            name: "Пенал раскладной, с прозрачным карманом",
            link: "https://lihitlab-jp.ru/images/A7941/A-7941_8.jpg",
            color: "Синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137637943/detail.aspx",
        },
        {
            id: 3,
            code: "A-7941-19",
            name: "Пенал раскладной, с прозрачным карманом",
            link: "https://lihitlab-jp.ru/images/A7941/A-7941_19.jpg",
            color: "Светло-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137637947/detail.aspx",
        },
        {
            id: 4,
            code: "A-7941-24",
            name: "Пенал раскладной, с прозрачным карманом",
            link: "https://lihitlab-jp.ru/images/A7941/A-7941_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137637945/detail.aspx",
        },
       
       
    ]

export const A7941POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7941/A-7941_poi1.jpg",
        },     
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7941/A-7941_poi2.jpg",
        }, 
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7941/A-7941_poi3.jpg",
        },      

    ]