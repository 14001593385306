export const N1964 =
    [
        {
            id: 1,
            code: "N-1964-2",
            name: "Блокнот перекидной на пружине формата A6 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1964/N-1964_2.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164791033/detail.aspx",
        },
        {
            id: 2,
            code: "N-1964-3",
            name: "Блокнот перекидной на пружине формата A6 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1964/N-1964_3.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164791034/detail.aspx",
        },
        {
            id: 3,
            code: "N-1964-5",
            name: "Блокнот перекидной на пружине формата A6 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1964/N-1964_5.jpg",
            color: "Желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164791035/detail.aspx",
        },
        {
            id: 4,
            code: "N-1964-10",
            name: "Блокнот перекидной на пружине формата A6 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1964/N-1964_10.jpg",
            color: "Фиолетовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164791037/detail.aspx",
        },    
        {
            id: 5,
            code: "N-1964-16",
            name: "Блокнот перекидной на пружине формата A6 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1964/N-1964_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164791038/detail.aspx",
        },  
        {
            id: 6,
            code: "N-1964-19",
            name: "Блокнот перекидной на пружине формата A6 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1964/N-1964_19.jpg",
            color: "Светло-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164791039/detail.aspx",
        },    
       
    ]

export const N1964POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N1964/N-1964_poi1.jpg",
        },      

    ]