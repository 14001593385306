export const A7912 =
    [
        {
            id: 3,
            code: "A-7912-11",
            name: "Папка-сумка двухсторонняя L",
            link: "https://lihitlab-jp.ru/images/A7912/A-7912_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145548690/detail.aspx",

        },
        {
            id: 4,
            code: "A-7912-16",
            name: "Папка-сумка двухсторонняя L",
            link: "https://lihitlab-jp.ru/images/A7912/A-7912_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145548691/detail.aspx",

        },
        {
            id: 5,
            code: "A-7912-24",
            name: "Папка-сумка двухсторонняя L",
            link: "https://lihitlab-jp.ru/images/A7912/A-7912_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145548696/detail.aspx",

        },
       
    ]

export const A7912POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7912/A-7912_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7912/A-7912_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7912/A-7912_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7912/A-7912_poi4.jpg",
        },

    ]