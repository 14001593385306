export const A7574 =
    [
        {
            id: 1,
            code: "A-7574-4",
            name: "Сумка поясная",
            link: "https://lihitlab-jp.ru/images/A7574/A-7574_4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/165454032/detail.aspx",

        },
        {
            id: 2,
            code: "A-7574-11",
            name: "Сумка поясная",
            link: "https://lihitlab-jp.ru/images/A7574/A-7574_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/165454035/detail.aspx",

        },
        {
            id: 3,
            code: "A-7574-22",
            name: "Сумка поясная",
            link: "https://lihitlab-jp.ru/images/A7574/A-7574_22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/165454036/detail.aspx",

        },
        {
            id: 4,
            code: "A-7574-24",
            name: "Сумка поясная",
            link: "https://lihitlab-jp.ru/images/A7574/A-7574_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/165454038/detail.aspx",

        },
       
    ]

export const A7574POI =
    [
        
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7574/A-7574_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7574/A-7574_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7574/A-7574_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7574/A-7574_poi5.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7574/A-7574_poi7.jpg",
        },

    ]