export const N3100 =
    [
        {
            id: 1,
            code: "N-3100-5",
            name: "Блокнот перекидной на пружине формата B7 со сменными листами (40 листов)",
            link: "https://lihitlab-jp.ru/images/N3100/N-3100_5.jpg",
            color: "Желттый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164873293/detail.aspx",
        },
        {
            id: 2,
            code: "N-3100-8",
            name: "Блокнот перекидной на пружине формата B7 со сменными листами (40 листов)",
            link: "https://lihitlab-jp.ru/images/N3100/N-3100_8.jpg",
            color: "Голубой",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164873294/detail.aspx",
        },
        {
            id: 3,
            code: "N-3100-10",
            name: "Блокнот перекидной на пружине формата B7 со сменными листами (40 листов)",
            link: "https://lihitlab-jp.ru/images/N3100/N-3100_10.jpg",
            color: "Фиолетовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164873290/detail.aspx",
        },
        {
            id: 4,
            code: "N-3100-12",
            name: "Блокнот перекидной на пружине формата B7 со сменными листами (40 листов)",
            link: "https://lihitlab-jp.ru/images/N3100/N-3100_12.jpg",
            color: "Розовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164873291/detail.aspx",
        },        
       
    ]

export const N3100POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N3100/N-3100_poi1.jpg",
        },
      

    ]