export const A7721 =
    [
        {
            id: 1,
            code: "A-7721-1",
            name: "Магнитная клипса Медвежонок",
            link: "https://lihitlab-jp.ru/images/A7721/A-7721-1.jpg",
            color: "Коричневый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50596776/detail.aspx",

        },
        {
            id: 2,
            code: "A-7721-2",
            name: "Магнитная клипса Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7721/A-7721-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50596777/detail.aspx",
        },
        {
            id: 3,
            code: "A-7721-3",
            name: "Магнитная клипса Черный кот",
            link: "https://lihitlab-jp.ru/images/A7721/A-7721-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50596778/detail.aspx",
        },
        {
            id: 4,
            code: "A-7721-4",
            name: "Магнитная клипса Серый кот",
            link: "https://lihitlab-jp.ru/images/A7721/A-7721-4.jpg",
            color: "Серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50596779/detail.aspx",
        },
        {
            id: 5,
            code: "A-7721-5",
            name: "Магнитная клипса Свинка",
            link: "https://lihitlab-jp.ru/images/A7721/A-7721-5.jpg",
            color: "Розовый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50596780/detail.aspx",
        },
        {
            id: 6,
            code: "A-7721-6",
            name: "Магнитная клипса Панда",
            link: "https://lihitlab-jp.ru/images/A7721/A-7721-6.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50596781/detail.aspx",
        },
    ]


export const A7721POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7721/A-7721_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7721/A-7721_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7721/A-7721_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7721/A-7721_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7721/A-7721_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7721/A-7721_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7721/A-7721_poi7.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7721/A-7721_poi8.jpg",
        },
        {
            id: 9,
            link: "https://lihitlab-jp.ru/images/A7721/A-7721_poi9.jpg",
        },
        {
            id: 10,
            link: "https://lihitlab-jp.ru/images/A7721/A-7721_poi10.jpg",
        },
        {
            id: 11,
            link: "https://lihitlab-jp.ru/images/A7721/A-7721_poi11.jpg",
        },
        {
            id: 12,
            link: "https://lihitlab-jp.ru/images/A7721/A-7721_poi12.jpg",
        },
        {
            id: 13,
            link: "https://lihitlab-jp.ru/images/A7721/A-7721_poi13.jpg",
        },

    ]