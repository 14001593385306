export const A7652 =
    [
        {
            id: 1,
            code: "A-7652-4",
            name: "Пенал плоский",
            link: "https://lihitlab-jp.ru/images/A7652/A-7652_4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137621524/detail.aspx",

        },
        {
            id: 2,
            code: "A-7652-6",
            name: "Пенал плоский",
            link: "https://lihitlab-jp.ru/images/A7652/A-7652_6.jpg",
            color: "Желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137621525/detail.aspx",

        },
        {
            id: 3,
            code: "A-7652-11",
            name: "Пенал плоский",
            link: "https://lihitlab-jp.ru/images/A7652/A-7652_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137621529/detail.aspx",

        },
        {
            id: 4,
            code: "A-7652-24",
            name: "Пенал плоский",
            link: "https://lihitlab-jp.ru/images/A7652/A-7652_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137621527/detail.aspx",

        },
       
    ]

export const A7652POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7652/A-7652_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7652/A-7652_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7652/A-7652_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7652/A-7652_poi4.jpg",
        },
       

    ]