export const A7900 =
    [
        {
            id: 1,
            code: "A-7900-3",
            name: "Пенал-косметичка M",
            link: "https://lihitlab-jp.ru/images/A7900/A-7900_3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186069809/detail.aspx",

        },
        {
            id: 2,
            code: "A-7900-5",
            name: "Пенал-косметичка M",
            link: "https://lihitlab-jp.ru/images/A7900/A-7900_5.jpg",
            color: "Желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186069810/detail.aspx",

        },
        {
            id: 3,
            code: "A-7900-11",
            name: "Пенал-косметичка M",
            link: "https://lihitlab-jp.ru/images/A7900/A-7900_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186069811/detail.aspx",

        },
        {
            id: 4,
            code: "A-7900-16",
            name: "Пенал-косметичка M",
            link: "https://lihitlab-jp.ru/images/A7900/A-7900_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186069812/detail.aspx",

        },
        {
            id: 5,
            code: "A-7900-24",
            name: "Пенал-косметичка M",
            link: "https://lihitlab-jp.ru/images/A7900/A-7900_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/186069814/detail.aspx",

        },
       
    ]

export const A7900POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7900/A-7900_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7900/A-7900_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7900/A-7900_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7900/A-7900_ist1.jpg",
        },
        

    ]