export const NOTEBOOK = [

    {
        id: 80,
        code: "N-2993",
        name: "Блокнот на пружине с обложкой из искусстенной кожи формата А5 со сменными листами (50 листов)",
        link: "https://lihitlab-jp.ru/images/N2993/N-2993.jpg",
        cardsRoute: "/catalog/note-book/n2993",
        bestseller: false,
        new: false,
      },
    {
      id: 80,
      code: "N-2658",
      name: "Блокнот перекидной на пружине формата А5 со сменными листами (30 листов)",
      link: "https://lihitlab-jp.ru/images/N2658/N-2658.jpg",
      cardsRoute: "/catalog/cube-fiz/n2658",
      bestseller: false,
      new: false,
    },
    {
      id: 81,
      code: "N-2641",
      name: "Блокнот перекидной на пружине формата А5 со сменными листами (70 листов)",
      link: "https://lihitlab-jp.ru/images/N2641/N-2641.jpg",
      cardsRoute: "/catalog/cube-fiz/n2641",
      bestseller: false,
      new: false,
    },
    {
      id: 82,
      code: "N-2608",
      name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
      link: "https://lihitlab-jp.ru/images/N2608/N-2608.jpg",
      cardsRoute: "/catalog/cube-fiz/n2608",
      bestseller: false,
      new: false,
    },
    {
      id: 83,
      code: "N-2611",
      name: "Блокнот перекидной на пружине формата B5 со сменными листами (70 листов)",
      link: "https://lihitlab-jp.ru/images/N2611/N-2611.jpg",
      cardsRoute: "/catalog/cube-fiz/n2611",
      bestseller: false,
      new: false,
    },
    {
        id: 84,
        code: "N-1670",
        name: "Блокнот перекидной на пружине формата B7 со сменными листами (50 листов)",
        link: "https://lihitlab-jp.ru/images/N1670/N-1670.jpg",
        cardsRoute: "/catalog/hirakuno/n1670",
        bestseller: false,
        new: false,
      },
      {
        id: 84,
        code: "N-1671",
        name: "Блокнот перекидной на пружине формата A6 со сменными листами (50 листов)",
        link: "https://lihitlab-jp.ru/images/N1671/N-1671.jpg",
        cardsRoute: "/catalog/hirakuno/n1671",
        bestseller: false,
        new: false,
      },
      {
        id: 85,
        code: "N-1672",
        name: "Блокнот перекидной на пружине формата B6 со сменными листами (50 листов)",
        link: "https://lihitlab-jp.ru/images/N1672/N-1672.jpg",
        cardsRoute: "/catalog/hirakuno/n1672",
        bestseller: false,
        new: false,
      },
      {
        id: 86,
        code: "N-1673",
        name: "Блокнот перекидной на пружине формата A5 со сменными листами (50 листов)",
        link: "https://lihitlab-jp.ru/images/N1673/N-1673.jpg",
        cardsRoute: "/catalog/hirakuno/n1673",
        bestseller: false,
        new: false,
      },
      {
        id: 87,
        code: "N-1674",
        name: "Блокнот перекидной на пружине формата B5 со сменными листами (50 листов)",
        link: "https://lihitlab-jp.ru/images/N1674/N-1674.jpg",
        cardsRoute: "/catalog/hirakuno/n1674",
        bestseller: false,
        new: false,
      },
      {
        id: 88,
        code: "N-1677",
        name: "Блокнот перекидной на пружине формата А6/А5Е со сменными листами (10 листов)",
        link: "https://lihitlab-jp.ru/images/N1677/N-1677.jpg",
        cardsRoute: "/catalog/hirakuno/n1677",
        bestseller: false,
        new: false,
      },
      {
        id: 89,
        code: "N-1678",
        name: "Блокнот перекидной на пружине формата B6/B5Е со сменными листами (10 листов)",
        link: "https://lihitlab-jp.ru/images/N1678/N-1678.jpg",
        cardsRoute: "/catalog/hirakuno/n1678",
        bestseller: false,
        new: false,
      },
      {
        id: 90,
        code: "N-3100",
        name: "Блокнот перекидной на пружине формата B7",
        link: "https://lihitlab-jp.ru/images/N3100/N-3100.jpg",
        cardsRoute: "/catalog/soffice/n3100",
        bestseller: false,
        new: false,
      },
      {
        id: 91,
        code: "N-3101",
        name: "Блокнот перекидной на пружине формата A6",
        link: "https://lihitlab-jp.ru/images/N3101/N-3101.jpg",
        cardsRoute: "/catalog/soffice/n3101",
        bestseller: false,
        new: false,
      },
      {
        id: 92,
        code: "N-3103",
        name: "Блокнот перекидной на пружине формата A5",
        link: "https://lihitlab-jp.ru/images/N3103/N-3103.jpg",
        cardsRoute: "/catalog/soffice/n3103",
        bestseller: false,
        new: false,
      },
      {
        id: 93,
        code: "N-3104",
        name: "Блокнот перекидной на пружине формата B5",
        link: "https://lihitlab-jp.ru/images/N3104/N-3104.jpg",
        cardsRoute: "/catalog/soffice/n3104",
        bestseller: false,
        new: false,
      },
      {
        id: 94,
        code: "N-1961",
        name: "Блокнот перекидной на пружине формата B7 со сменными листами (40 листов)",
        link: "https://lihitlab-jp.ru/images/N1961/N-1961.jpg",
        cardsRoute: "/catalog/pastello/n1961",
        bestseller: false,
        new: false,
      },
      {
        id: 95,
        code: "N-1908",
        name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
        link: "https://lihitlab-jp.ru/images/N1908/N-1908.jpg",
        cardsRoute: "/catalog/pastello/n1908",
        bestseller: false,
        new: false,
      },
      {
        id: 96,
        code: "N-1969",
        name: "Блокнот перекидной на пружине формата B6 со сменными листами (30 листов)",
        link: "https://lihitlab-jp.ru/images/N1969/N-1969.jpg",
        cardsRoute: "/catalog/pastello/n1969",
        bestseller: false,
        new: false,
      },
      {
        id: 97,
        code: "N-1958",
        name: "Блокнот перекидной на пружине формата A5 со сменными листами (30 листов)",
        link: "https://lihitlab-jp.ru/images/N1958/N-1958.jpg",
        cardsRoute: "/catalog/pastello/n1958",
        bestseller: false,
        new: false,
      },
      {
        id: 98,
        code: "N-1964",
        name: "Блокнот перекидной на пружине формата A6 со сменными листами (30 листов)",
        link: "https://lihitlab-jp.ru/images/N1964/N-1964.jpg",
        cardsRoute: "/catalog/pastello/n1964",
        bestseller: false,
        new: false,
      },
      
    
  ]