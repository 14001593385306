export const A7585 =
    [
        {
            id: 1,
            code: "A-7585-4",
            name: "Пенал-портмоне",
            link: "https://lihitlab-jp.ru/images/A7585/A-7585-4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50563056/detail.aspx",

        },
        {
            id: 2,
            code: "A-7585-11",
            name: "Пенал-портмоне",
            link: "https://lihitlab-jp.ru/images/A7585/A-7585-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50563057/detail.aspx",

        },
        {
            id: 3,
            code: "A-7585-22",
            name: "Пенал-портмоне",
            link: "https://lihitlab-jp.ru/images/A7585/A-7585-22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50563058/detail.aspx",

        },
        {
            id: 4,
            code: "A-7585-24",
            name: "Пенал-портмоне",
            link: "https://lihitlab-jp.ru/images/A7585/A-7585-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50563059/detail.aspx",

        },
       
    ]

export const A7585POI =
    [
      
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7585/A-7585_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7585/A-7585_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7585/A-7585_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7585/A-7585_poi12.jpg",
        },
          
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7585/A-7585_ist1.jpg",
        },

    ]