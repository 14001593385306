export const A7780 =
    [
        {
            id: 2,
            code: "A-7780-2",
            name: "Пенал лежащий, мини Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7780/A-7780-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50055066/detail.aspx",
        },
        {
            id: 3,
            code: "A-7780-3",
            name: "Пенал лежащий, мини Черный кот",
            link: "https://lihitlab-jp.ru/images/A7780/A-7780-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50055067/detail.aspx",
        },
        {
            id: 4,
            code: "A-7780-4",
            name: "Пенал лежащий, мини Cерый кот",
            link: "https://lihitlab-jp.ru/images/A7780/A-7780-4.jpg",
            color: "Серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50055068/detail.aspx",
        },
        {
            id: 7,
            code: "A-7780-7",
            name: "Пенал лежащий, мини Белый кот",
            link: "https://lihitlab-jp.ru/images/A7780/A-7780-7.jpg",
            color: "белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50055069/detail.aspx",
        },
        {
            id: 10,
            code: "A-7780-10",
            name: "Пенал лежащий, мини Пингвин",
            link: "https://lihitlab-jp.ru/images/A7780/A-7780-10.jpg",
            color: "Черно-серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50055070/detail.aspx",
        },
        {
            id: 16,
            code: "A-7780-16",
            name: "Пенал лежащий, мини Морской Котик",
            link: "https://lihitlab-jp.ru/images/A7780/A-7780-16.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50055071/detail.aspx",
        },
        {
            id: 17,
            code: "A-7780-17",
            name: "Пенал лежащий, мини Цыпленок",
            link: "https://lihitlab-jp.ru/images/A7780/A-7780-17.jpg",
            color: "Желтый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50055072/detail.aspx",
        },
        {
            id: 18,
            code: "A-7780-18",
            name: "Пенал лежащий, мини Песик Мопс",
            link: "https://lihitlab-jp.ru/images/A7780/A-7780-18.jpg",
            color: "Желтый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50055073/detail.aspx",
        },
    ]


export const A7780POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7780/A-7780_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7780/A-7780_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7780/A-7780_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7780/A-7780_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7780/A-7780_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7780/A-7780_poi6.jpg",
        },
      
      

    ]