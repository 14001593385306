export const A7717 =
    [
        {
            id: 1,
            code: "A-7717-1",
            name: "Пенал узкий, длинный Медвежонок",
            link: "https://lihitlab-jp.ru/images/A7717/A-7717-1.jpg",
            color: "Коричневый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50009282/detail.aspx",

        },
        {
            id: 2,
            code: "A-7717-2",
            name: "Пенал узкий, длинный Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7717/A-7717-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50009284/detail.aspx",
        },
        {
            id: 3,
            code: "A-7717-3",
            name: "Пенал узкий, длинный Черный кот",
            link: "https://lihitlab-jp.ru/images/A7717/A-7717-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50009285/detail.aspx",
        },
        {
            id: 4,
            code: "A-7717-4",
            name: "Пенал узкий, длинный Серый кот",
            link: "https://lihitlab-jp.ru/images/A7717/A-7717-4.jpg",
            color: "Серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50009286/detail.aspx",
        },
        {
            id: 5,
            code: "A-7717-5",
            name: "Пенал узкий, длинный Свинка",
            link: "https://lihitlab-jp.ru/images/A7717/A-7717-5.jpg",
            color: "Розовый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50009287/detail.aspx",
        },
        {
            id: 6,
            code: "A-7717-6",
            name: "Пенал узкий, длинный Панда",
            link: "https://lihitlab-jp.ru/images/A7717/A-7717-6.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50009288/detail.aspx",
        },
    ]


export const A7717POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7717/A-7717_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7717/A-7717_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7717/A-7717_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7717/A-7717_poi4.jpg",
        },

    ]