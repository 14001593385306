export const A7720 =
    [
        {
            id: 1,
            code: "A-7720-1",
            name: "Пенал с ремешком Медвежонок",
            link: "https://lihitlab-jp.ru/images/A7720/A-7720-1.jpg",
            color: "Коричневый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50130671/detail.aspx",

        },
        {
            id: 2,
            code: "A-7720-2",
            name: "Пенал с ремешком Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7720/A-7720-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50130672/detail.aspx",
        },
        {
            id: 3,
            code: "A-7720-3",
            name: "Пенал с ремешком Черный кот",
            link: "https://lihitlab-jp.ru/images/A7720/A-7720-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50130673/detail.aspx",
        },
        {
            id: 4,
            code: "A-7720-4",
            name: "Пенал с ремешком Серый кот",
            link: "https://lihitlab-jp.ru/images/A7720/A-7720-4.jpg",
            color: "Серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50130674/detail.aspx",
        },
        {
            id: 5,
            code: "A-7720-5",
            name: "Пенал с ремешком Свинка",
            link: "https://lihitlab-jp.ru/images/A7720/A-7720-5.jpg",
            color: "Розовый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50130675/detail.aspx",
        },
        {
            id: 6,
            code: "A-7720-6",
            name: "Пенал с ремешком Панда",
            link: "https://lihitlab-jp.ru/images/A7720/A-7720-6.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50130676/detail.aspx",
        },
    ]


export const A7720POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7720/A-7720_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7720/A-7720_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7720/A-7720_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7720/A-7720_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7720/A-7720_poi5.jpg",
        },

    ]