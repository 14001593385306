import './CardsImg.css';

import { useState, useEffect } from 'react';

import CardImg from '../CardImg/CardImg';

function CardsImg(props) {
  return (
    <div class='catalog__cards-list'>
      <h2 class='catalog__cards-list-title'>{props.title}</h2>

      <div className='catalog__main-cards-img'>
        {props.catalogItem.map((card) => (
          <CardImg
            link={card.link}
            name={card.name}
            code={card.code}
            bestseller={card.bestseller}
            new={card.new}
            cardsRoute={card.cardsRoute}
          />
        ))}
      </div>

    
    </div>
  );
}

export default CardsImg;
