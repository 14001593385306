export const A7725 =
    [
        {
            id: 2,
            code: "A-7725-2",
            name: "Магнит канцелярский Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7725/A-7725_2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137904422/detail.aspx",
        },
        {
            id: 3,
            code: "A-7725-3",
            name: "Магнит канцелярский Черный кот",
            link: "https://lihitlab-jp.ru/images/A7725/A-7725_3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137904423/detail.aspx",
        },
        {
            id: 7,
            code: "A-7725-7",
            name: "Магнит канцелярский Белый кот",
            link: "https://lihitlab-jp.ru/images/A7725/A-7725_7.jpg",
            color: "белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137904420/detail.aspx",
        },
        {
            id: 5,
            code: "A-7725-8",
            name: "Магнит канцелярский Бостон терьер",
            link: "https://lihitlab-jp.ru/images/A7725/A-7725_8.jpg",
            color: "черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137904425/detail.aspx",
        },
        {
            id: 9,
            code: "A-7725-6",
            name: "Магнит канцелярский Попугай",
            link: "https://lihitlab-jp.ru/images/A7725/A-7725_9.jpg",
            color: "Желто-зеленый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137904421/detail.aspx",
        },
        {
            id: 10,
            code: "A-7725-10",
            name: "Магнит канцелярский Пингвин",
            link: "https://lihitlab-jp.ru/images/A7725/A-7725_10.jpg",
            color: "Черно-серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137904424/detail.aspx",
        },
    ]


export const A7725POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7725/A-7725_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7725/A-7725_poi2.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7725/A-7725_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7725/A-7725_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7725/A-7725_poi6.jpg",
        },
      

    ]