export const A7653 =
    [
        {
            id: 1,
            code: "A-7653-4",
            name: "Пенал плоский большой",
            link: "https://lihitlab-jp.ru/images/A7653/A-7653_4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185954240/detail.aspx",

        },
        {
            id: 2,
            code: "A-7653-6",
            name: "Пенал плоский большой",
            link: "https://lihitlab-jp.ru/images/A7653/A-7653_6.jpg",
            color: "Желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185954241/detail.aspx",

        },
        {
            id: 4,
            code: "A-7653-24",
            name: "Пенал плоский большой",
            link: "https://lihitlab-jp.ru/images/A7653/A-7653_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185954242/detail.aspx",

        },
       
    ]

export const A7653POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7653/A-7653_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7653/A-7653_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7653/A-7653_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7653/A-7653_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7653/A-7653_poi5.jpg",
        },
       

    ]