export const A7784 =
    [
        {
            id: 2,
            code: "A-7784-2",
            name: "Кошелек Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7784/A-7784-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50118650/detail.aspx",
        },
        {
            id: 3,
            code: "A-7784-3",
            name: "Кошелек Черный кот",
            link: "https://lihitlab-jp.ru/images/A7784/A-7784-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50118651/detail.aspx",
        },
        {
            id: 4,
            code: "A-7784-4",
            name: "Кошелек Cерый кот",
            link: "https://lihitlab-jp.ru/images/A7784/A-7784-4.jpg",
            color: "Серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50118652/detail.aspx",
        },
        {
            id: 7,
            code: "A-7784-7",
            name: "Кошелек Белый кот",
            link: "https://lihitlab-jp.ru/images/A7784/A-7784-7.jpg",
            color: "белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50118653/detail.aspx",
        },
        {
            id: 10,
            code: "A-7784-10",
            name: "Кошелек Пингвин",
            link: "https://lihitlab-jp.ru/images/A7784/A-7784-10.jpg",
            color: "Черно-серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50118654/detail.aspx",
        },

    ]


export const A7784POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7784/A-7784_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7784/A-7784_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7784/A-7784_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7784/A-7784_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7784/A-7784_ist1.jpg",
        },

    ]