export const CUBEFIZ = [
      {
        id: 80,
        code: "N-2658",
        name: "Блокнот перекидной на пружине формата А5 со сменными листами (30 листов)",
        link: "https://lihitlab-jp.ru/images/N2658/N-2658.jpg",
        cardsRoute: "/catalog/cube-fiz/n2658",
        bestseller: false,
        new: false,
      },
      {
        id: 81,
        code: "N-2641",
        name: "Блокнот перекидной на пружине формата А5 со сменными листами (70 листов)",
        link: "https://lihitlab-jp.ru/images/N2641/N-2641.jpg",
        cardsRoute: "/catalog/cube-fiz/n2641",
        bestseller: false,
        new: false,
      },
      {
        id: 82,
        code: "N-2608",
        name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
        link: "https://lihitlab-jp.ru/images/N2608/N-2608.jpg",
        cardsRoute: "/catalog/cube-fiz/n2608",
        bestseller: false,
        new: false,
      },
      {
        id: 83,
        code: "N-2611",
        name: "Блокнот перекидной на пружине формата B5 со сменными листами (70 листов)",
        link: "https://lihitlab-jp.ru/images/N2611/N-2611.jpg",
        cardsRoute: "/catalog/cube-fiz/n2611",
        bestseller: false,
        new: false,
      },
      
    ]