export const F7740 =
    [
        {
            id: 1,
            code: "F-7740-1",
            name: "Папка-планшет с зажимом А4",
            link: "https://lihitlab-jp.ru/images/F7740/F-7740_1.jpg",
            color: "Прозрачный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164946211/detail.aspx",
        },
        {
            id: 1,
            code: "F-7740-3",
            name: "Папка-планшет с зажимом А4",
            link: "https://lihitlab-jp.ru/images/F7740/F-7740_3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164946213/detail.aspx",
        },
        {
            id: 2,
            code: "F-7740-8",
            name: "Папка-планшет с зажимом А4",
            link: "https://lihitlab-jp.ru/images/F7740/F-7740_8.jpg",
            color: "Синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164946214/detail.aspx",
        },
        {
            id: 3,
            code: "F-7740-19",
            name: "Папка-планшет с зажимом А4",
            link: "https://lihitlab-jp.ru/images/F7740/F-7740_19.jpg",
            color: "Светло-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164946215/detail.aspx",
        },
        {
            id: 4,
            code: "F-7740-24",
            name: "Папка-планшет с зажимом А4",
            link: "https://lihitlab-jp.ru/images/F7740/F-7740_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164946217/detail.aspx",
        },
       
       
    ]

export const F7740POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/F7740/F-7740_poi1.jpg",
        },     
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/F7740/F-7740_poi2.jpg",
        }, 
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/F7740/F-7740_poi3.jpg",
        },  

    ]