export const A7554 =
    [
        {
            id: 1,
            code: "A-7554-4",
            name: "Папка-сумка органайзер А4",
            link: "https://lihitlab-jp.ru/images/A7554/A-7554-4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50231259/detail.aspx",

        },
        {
            id: 2,
            code: "A-7554-6",
            name: "Папка-сумка органайзер А4",
            link: "https://lihitlab-jp.ru/images/A7554/A-7554-6.jpg",
            color: "Желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50231260/detail.aspx",

        },
        {
            id: 3,
            code: "A-7554-9",
            name: "Папка-сумка органайзер А4",
            link: "https://lihitlab-jp.ru/images/A7554/A-7554-9.jpg",
            color: "Коричневый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50231261/detail.aspx",

        },
        {
            id: 4,
            code: "A-7554-24",
            name: "Папка-сумка органайзер А4",
            link: "https://lihitlab-jp.ru/images/A7554/A-7554-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50231262/detail.aspx",

        },
       
    ]

export const A7554POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7554/A-7554_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7554/A-7554_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7554/A-7554_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7554/A-7554_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7554/A-7554_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7554/A-7554_img1.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7554/A-7554_img2.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7554/A-7554_img3.jpg",
        },
        

    ]