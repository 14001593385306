export const A7686 =
    [
        {
            id: 1,
            code: "A-7686-3",
            name: "Пенал-органайзер дорожный",
            link: "https://lihitlab-jp.ru/images/A7686/A-7686_3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185964847/detail.aspx",

        },
        {
            id: 3,
            code: "A-7686-11",
            name: "Пенал-органайзер дорожный",
            link: "https://lihitlab-jp.ru/images/A7686/A-7686_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185964848/detail.aspx",

        },
        {
            id: 4,
            code: "A-7686-24",
            name: "Пенал-органайзер дорожный",
            link: "https://lihitlab-jp.ru/images/A7686/A-7686_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185964849/detail.aspx",

        },
       
    ]

export const A7686POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7686/A-7686_opn1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7686/A-7686_poi2.jpg",
        },
        
    ]