export const A7687 =
    [
        {
            id: 1,
            code: "A-7687-3",
            name: "Пенал-органайзер плоский",
            link: "https://lihitlab-jp.ru/images/A7687/A-7687_3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137624518/detail.aspx",

        },
        {
            id: 2,
            code: "A-7687-6",
            name: "Пенал-органайзер плоский",
            link: "https://lihitlab-jp.ru/images/A7687/A-7687_6.jpg",
            color: "Желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137624515/detail.aspx",

        },
        {
            id: 3,
            code: "A-7687-11",
            name: "Пенал-органайзер плоский",
            link: "https://lihitlab-jp.ru/images/A7687/A-7687_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137624516/detail.aspx",

        },
        {
            id: 4,
            code: "A-7687-24",
            name: "Пенал-органайзер плоский",
            link: "https://lihitlab-jp.ru/images/A7687/A-7687_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/137624517/detail.aspx",

        },
       
    ]

export const A7687POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7687/A-7687_opn1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7687/A-7687_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7687/A-7687_poi2.jpg",
        },
        

    ]