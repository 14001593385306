import './Card.css';

function Card(props) {




  return (

    <article className={"catalog__card"}>

      <img className="catalog__img" src={props.link} alt={props.name} />
      <h3 className="catalog__card-subtitle">{props.name}</h3>
      <div className='stars'></div>
      <p className="catalog__card-title">Артикул: {props.code}</p>
      <p className="catalog__card-title">Цвет: {props.color}</p>

      <a href={props.linkWB} className="catalog__card-link-wb" target="_blank">Купить на WB</a>
      
      

      <div className="catalog__card-wrapper-icon">

      <div className={props.new ? "catalog__card-new catalog__card-new-active" :  "catalog__card-new"}>Новинка</div>

        <div className={props.bestseller ? "catalog__card-bestseller catalog__card-bestseller-active" :  "catalog__card-bestseller"}>Хит</div>

        


      </div>

      


    </article>

  );
}

export default Card;
