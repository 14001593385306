export const SMARTFIT = [

    {
        id: 29,
        code: "A-8800",
        name: "Папка-сумка складная",
        link: "https://lihitlab-jp.ru/images/A8800/A-8800.jpg",
        cardsRoute: "/catalog/smartfit/a8800",
        bestseller: false,
        new: true,
    },
    {
        id: 30,
        code: "A-7701",
        name: "Клатч",
        link: "https://lihitlab-jp.ru/images/A7701/A-7701.jpg",
        cardsRoute: "/catalog/smartfit/a7701",
        bestseller: false,
        new: false,
    },
    {
        id: 31,
        code: "A-7702",
        name: "Клатч большой",
        link: "https://lihitlab-jp.ru/images/A7702/A-7702.jpg",
        cardsRoute: "/catalog/smartfit/a7702",
        bestseller: false,
        new: false,
    },
    {
        id: 32,
        code: "A-7574",
        name: "Сумка поясная",
        link: "https://lihitlab-jp.ru/images/A7574/A-7574.jpg",
        cardsRoute: "/catalog/smartfit/a7574",
        bestseller: false,
        new: false,
    },
    {
        id: 33,
        code: "A-7575",
        name: "Папка-сумка для гаджетов А5",
        link: "https://lihitlab-jp.ru/images/A7575/A-7575.jpg",
        cardsRoute: "/catalog/smartfit/a7575",
        bestseller: false,
        new: false,
    },
    {
        id: 34,
        code: "A-7576",
        name: "Папка-сумка для гаджетов В5",
        link: "https://lihitlab-jp.ru/images/A7576/A-7576.jpg",
        cardsRoute: "/catalog/smartfit/a7576",
        bestseller: false,
        new: false,
    },
    {
        id: 35,
        code: "A-7577",
        name: "Папка-сумка для гаджетов А4",
        link: "https://lihitlab-jp.ru/images/A7577/A-7577.jpg",
        cardsRoute: "/catalog/smartfit/a7577",
        bestseller: false,
        new: false,
    },
    {
        id: 36,
        code: "N-1627",
        name: "Блокнот-органайзер B5",
        link: "https://lihitlab-jp.ru/images/N1627/N-1627.jpg",
        cardsRoute: "/catalog/smartfit/n1627",
        bestseller: false,
        new: false,
    },
    {
        id: 37,
        code: "N-1647",
        name: "Блокнот-органайзер A5",
        link: "https://lihitlab-jp.ru/images/N1647/N-1647.jpg",
        cardsRoute: "/catalog/smartfit/n1647",
        bestseller: false,
        new: false,
    },
    {
        id: 38,
        code: "A-7585",
        name: "Пенал-портмоне",
        link: "https://lihitlab-jp.ru/images/A7585/A-7585.jpg",
        cardsRoute: "/catalog/smartfit/a7585",
        bestseller: false,
        new: false,
      },
      {
        id: 39,
        code: "A-7581",
        name: "Сумка c увеличивающейся толщиной B4",
        link: "https://lihitlab-jp.ru/images/A7581/A-7581.jpg",
        cardsRoute: "/catalog/smartfit/a7581",
        bestseller: false,
        new: false,
      },
      {
        id: 41,
        code: "A-7582",
        name: "Сумка c увеличивающейся толщиной B4",
        link: "https://lihitlab-jp.ru/images/A7582/A-7582.jpg",
        cardsRoute: "/catalog/smartfit/a7582",
        bestseller: false,
        new: false,
      },
      {
        id: 42,
        code: "A-7583",
        name: "Сумка-шоппер B4/A4",
        link: "https://lihitlab-jp.ru/images/A7583/A-7583.jpg",
        cardsRoute: "/catalog/smartfit/a7583",
        bestseller: false,
        new: false,
      },
      {
        id: 43,
        code: "A-7595",
        name: "Крючек для сумки",
        link: "https://lihitlab-jp.ru/images/A7595/A-7595.jpg",
        cardsRoute: "/catalog/smartfit/a7595",
        bestseller: false,
        new: false,
      },
      {
        id: 44,
        code: "A-7650",
        name: "Сумка B4 c удлиняющейся ручкой",
        link: "https://lihitlab-jp.ru/images/A7650/A-7650.jpg",
        cardsRoute: "/catalog/smartfit/a7650",
        bestseller: false,
        new: false,
      },
      {
        id: 44,
        code: "A-7651",
        name: "Сумка B4 c удлиняющейся ручкой широкая",
        link: "https://lihitlab-jp.ru/images/A7651/A-7651.jpg",
        cardsRoute: "/catalog/smartfit/a7651",
        bestseller: false,
        new: false,
      },
      {
        id: 45,
        code: "A-7554",
        name: "Папка-сумка органайзер А4",
        link: "https://lihitlab-jp.ru/images/A7554/A-7554.jpg",
        cardsRoute: "/catalog/smartfit/a7554",
        bestseller: false,
        new: false,
      },
      {
        id: 46,
        code: "A-7558",
        name: "Папка-сумка органайзер А5",
        link: "https://lihitlab-jp.ru/images/A7558/A-7558.jpg",
        cardsRoute: "/catalog/smartfit/a7558",
        bestseller: false,
        new: false,
      },
      {
        id: 47,
        code: "A-7559",
        name: "Папка-сумка органайзер А4",
        link: "https://lihitlab-jp.ru/images/A7559/A-7559.jpg",
        cardsRoute: "/catalog/smartfit/a7559",
        bestseller: false,
        new: false,
      },
      {
        id: 48,
        code: "A-7551",
        name: "Пенал-органайзер с одним отделом",
        link: "https://lihitlab-jp.ru/images/A7551/A-7551.jpg",
        cardsRoute: "/catalog/smartfit/a7551",
        bestseller: false,
        new: false,
      },
      {
        id: 49,
        code: "A-7552",
        name: "Пенал-органайзер с одним отделом узкий",
        link: "https://lihitlab-jp.ru/images/A7552/A-7552.jpg",
        cardsRoute: "/catalog/smartfit/a7552",
        bestseller: false,
        new: false,
      },
      {
        id: 50,
        code: "A-7555",
        name: "Пенал-органайзер с двумя отделами",
        link: "https://lihitlab-jp.ru/images/A7555/A-7555.jpg",
        cardsRoute: "/catalog/smartfit/a7555",
        bestseller: false,
        new: false,
      },
      {
        id: 51,
        code: "A-7556",
        name: "Пенал-органайзер с тремя отделами",
        link: "https://lihitlab-jp.ru/images/A7556/A-7556.jpg",
        cardsRoute: "/catalog/smartfit/a7556",
        bestseller: false,
        new: false,
      },
      {
        id: 52,
        code: "A-7557",
        name: "Пенал-органайзер с двумя отделами узкий",
        link: "https://lihitlab-jp.ru/images/A7557/A-7557.jpg",
        cardsRoute: "/catalog/smartfit/a7557",
        bestseller: false,
        new: false,
      },
      {
        id: 53,
        code: "A-7652",
        name: "Пенал плоский A-7652",
        link: "https://lihitlab-jp.ru/images/A7652/A-7652.jpg",
        cardsRoute: "/catalog/smartfit/a7652",
        bestseller: false,
        new: false,
      },
      {
        id: 54,
        code: "A-7653",
        name: "Пенал плоский большой A-7653",
        link: "https://lihitlab-jp.ru/images/A7653/A-7653.jpg",
        cardsRoute: "/catalog/smartfit/a7653",
        bestseller: false,
        new: false,
      },


]