export const N1670 =
    [
        {
            id: 1,
            code: "N-1670-0",
            name: "Блокнот перекидной на пружине формата B7 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N1670/N-1670_0.jpg",
            color: "Белый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145718335/detail.aspx",
        },
        {
            id: 2,
            code: "N-1670-11",
            name: "Блокнот перекидной на пружине формата B7 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N1670/N-1670_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145718333/detail.aspx",
        },
        {
            id: 3,
            code: "N-1670-24",
            name: "Блокнот перекидной на пружине формата B7 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N1670/N-1670_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145718336/detail.aspx",
        },
        {
            id: 4,
            code: "N-1670-26",
            name: "Блокнот перекидной на пружине формата B7 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N1670/N-1670_26.jpg",
            color: "Серый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145718334/detail.aspx",
        },
        
       
    ]

export const N1670POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N1670/N-1670_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/N1670/N-1670_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/N1670/N-1670_poi3.jpg",
        },
            

    ]