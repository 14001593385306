export const N1969 =
    [
        {
            id: 1,
            code: "N-1969-2",
            name: "Блокнот перекидной на пружине формата B6 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1969/N-1969_2.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163772653/detail.aspx",
        },
        {
            id: 2,
            code: "N-1969-3",
            name: "Блокнот перекидной на пружине формата B6 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1969/N-1969_3.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163772654/detail.aspx",
        },
        {
            id: 3,
            code: "N-1969-5",
            name: "Блокнот перекидной на пружине формата B6 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1969/N-1969_5.jpg",
            color: "Желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163772655/detail.aspx",
        },
        {
            id: 4,
            code: "N-1969-10",
            name: "Блокнот перекидной на пружине формата B6 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1969/N-1969_10.jpg",
            color: "Фиолетовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163772656/detail.aspx",
        },    
        {
            id: 5,
            code: "N-1969-16",
            name: "Блокнот перекидной на пружине формата B6 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1969/N-1969_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163772657/detail.aspx",
        },  
        {
            id: 6,
            code: "N-1969-19",
            name: "Блокнот перекидной на пружине формата B6 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N1969/N-1969_19.jpg",
            color: "Светло-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163772658/detail.aspx",
        },    
       
    ]

export const N1969POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N1969/N-1969_poi1.jpg",
        },      

    ]