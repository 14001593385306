export const A7782 =
    [
        {
            id: 2,
            code: "A-7782-2",
            name: "Пенал яйцо Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7782/A-7782-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50115871/detail.aspx",
        },
        {
            id: 3,
            code: "A-7782-3",
            name: "Пенал яйцо Черный кот",
            link: "https://lihitlab-jp.ru/images/A7782/A-7782-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50115872/detail.aspx",
        },
        {
            id: 4,
            code: "A-7782-4",
            name: "Пенал яйцо Cерый кот",
            link: "https://lihitlab-jp.ru/images/A7782/A-7782-4.jpg",
            color: "Серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50115873/detail.aspx",
        },
        {
            id: 7,
            code: "A-7782-7",
            name: "Пенал яйцо Белый кот",
            link: "https://lihitlab-jp.ru/images/A7782/A-7782-7.jpg",
            color: "белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50115874/detail.aspx",
        },
        {
            id: 10,
            code: "A-7782-10",
            name: "Пенал яйцо Пингвин",
            link: "https://lihitlab-jp.ru/images/A7782/A-7782-10.jpg",
            color: "Черно-серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50115875/detail.aspx",
        },

    ]


export const A7782POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7782/A-7782_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7782/A-7782_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7782/A-7782_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7782/A-7782_poi4.jpg",
        },

    ]