export const ACTACT = [
    {
        id: 55,
        code: "А-7694",
        name: "Пенал-тубус овальный",
        link: "https://lihitlab-jp.ru/images/A7694/A-7694.jpg",
        cardsRoute: "/catalog/actact/a7694",
        bestseller: false,
        new: false,
      },
      {
        id: 56,
        code: "А-7695",
        name: "Пенал-тубус овальный большой",
        link: "https://lihitlab-jp.ru/images/A7695/A-7695.jpg",
        cardsRoute: "/catalog/actact/a7695",
        bestseller: false,
        new: false,
      },
      {
        id: 57,
        code: "А-7670",
        name: "Сумка-рюкзак горизонтальная",
        link: "https://lihitlab-jp.ru/images/A7670/A-7670.jpg",
        cardsRoute: "/catalog/actact/a7670",
        bestseller: false,
        new: false,
      },
      {
        id: 58,
        code: "А-7672",
        name: "Сумка-рюкзак вертикальная",
        link: "https://lihitlab-jp.ru/images/A7672/A-7672.jpg",
        cardsRoute: "/catalog/actact/a7672",
        bestseller: false,
        new: false,
      },
      {
        id: 59,
        code: "А-7680",
        name: "Папка-сумка органайзер горизонтальная А5",
        link: "https://lihitlab-jp.ru/images/A7680/A-7680.jpg",
        cardsRoute: "/catalog/actact/a7680",
        bestseller: false,
        new: false,
      },
      {
        id: 60,
        code: "А-7681",
        name: "Папка-сумка органайзер горизонтальная А4",
        link: "https://lihitlab-jp.ru/images/A7681/A-7681.jpg",
        cardsRoute: "/catalog/actact/a7681",
        bestseller: false,
        new: false,
      },
      {
        id: 61,
        code: "А-7682",
        name: "Папка-сумка органайзер вертикальная А5",
        link: "https://lihitlab-jp.ru/images/A7682/A-7682.jpg",
        cardsRoute: "/catalog/actact/a7682",
        bestseller: false,
        new: false,
      },
      {
        id: 62,
        code: "А-7683",
        name: "Папка-сумка органайзер вертикальная А4",
        link: "https://lihitlab-jp.ru/images/A7683/A-7683.jpg",
        cardsRoute: "/catalog/actact/a7683",
        bestseller: false,
        new: false,
      },
      {
        id: 63,
        code: "А-7686",
        name: "Пенал-органайзер дорожный",
        link: "https://lihitlab-jp.ru/images/A7686/A-7686.jpg",
        cardsRoute: "/catalog/actact/a7686",
        bestseller: false,
        new: false,
      },
      {
        id: 64,
        code: "А-7687",
        name: "Пенал-органайзер плоский",
        link: "https://lihitlab-jp.ru/images/A7687/A-7687.jpg",
        cardsRoute: "/catalog/actact/a7687",
        bestseller: false,
        new: false,
      },
      {
        id: 65,
        code: "А-7688",
        name: "Пенал-косметичка",
        link: "https://lihitlab-jp.ru/images/A7688/A-7688.jpg",
        cardsRoute: "/catalog/actact/a7688",
        bestseller: false,
        new: false,
      },
      
    ]