export const A7722 =
    [
        {
            id: 1,
            code: "A-7722-1",
            name: "Стягиватель Медвежонок",
            link: "https://lihitlab-jp.ru/images/A7722/A-7722-1.jpg",
            color: "Коричневый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50604129/detail.aspx",

        },
        {
            id: 2,
            code: "A-7722-2",
            name: "Стягиватель Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7722/A-7722-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50604130/detail.aspx",
        },
        {
            id: 3,
            code: "A-7722-3",
            name: "Стягиватель Черный кот",
            link: "https://lihitlab-jp.ru/images/A7722/A-7722-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50604131/detail.aspx",
        },
        {
            id: 4,
            code: "A-7722-4",
            name: "Стягиватель Серый кот",
            link: "https://lihitlab-jp.ru/images/A7722/A-7722-4.jpg",
            color: "Серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50604132/detail.aspx",
        },
        {
            id: 5,
            code: "A-7722-5",
            name: "Стягиватель Свинка",
            link: "https://lihitlab-jp.ru/images/A7722/A-7722-5.jpg",
            color: "Розовый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50604133/detail.aspx",
        },
        {
            id: 6,
            code: "A-7722-6",
            name: "Стягиватель Панда",
            link: "https://lihitlab-jp.ru/images/A7722/A-7722-6.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50604135/detail.aspx",
        },
    ]


export const A7722POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7722/A-7722_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7722/A-7722_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7722/A-7722_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7722/A-7722_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7722/A-7722_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7722/A-7722_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7722/A-7722_poi7.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7722/A-7722_poi8.jpg",
        },
        {
            id: 9,
            link: "https://lihitlab-jp.ru/images/A7722/A-7722_poi9.jpg",
        },

    ]