export const N3101 =
    [
        {
            id: 1,
            code: "N-3101-5",
            name: "Блокнот перекидной на пружине формата A6 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N3101/N-3101_5.jpg",
            color: "Желттый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164875019/detail.aspx",
        },
        {
            id: 2,
            code: "N-3101-8",
            name: "Блокнот перекидной на пружине формата A6 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N3101/N-3101_8.jpg",
            color: "Голубой",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164875020/detail.aspx",
        },
        {
            id: 3,
            code: "N-3101-10",
            name: "Блокнот перекидной на пружине формата A6 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N3101/N-3101_10.jpg",
            color: "Фиолетовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164875021/detail.aspx",
        },
        {
            id: 4,
            code: "N-3101-12",
            name: "Блокнот перекидной на пружине формата A6 со сменными листами (30 листов)",
            link: "https://lihitlab-jp.ru/images/N3101/N-3101_12.jpg",
            color: "Розовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164875022/detail.aspx",
        },        
       
    ]

export const N3101POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N3101/N-3101_poi1.jpg",
        },
      

    ]