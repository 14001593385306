export const HINEMO = [
      {
        id: 65,
        code: "А-7900",
        name: "Пенал-косметичка M",
        link: "https://lihitlab-jp.ru/images/A7900/A-7900.jpg",
        cardsRoute: "/catalog/hinemo/a7900",
        bestseller: false,
        new: true,
      },
      {
        id: 66,
        code: "А-7901",
        name: "Пенал-косметичка L",
        link: "https://lihitlab-jp.ru/images/A7901/A-7901.jpg",
        cardsRoute: "/catalog/hinemo/a7901",
        bestseller: false,
        new: true,
      },
      {
        id: 67,
        code: "А-7902",
        name: "Пенал двухсторонний L-образный",
        link: "https://lihitlab-jp.ru/images/A7902/A-7902.jpg",
        cardsRoute: "/catalog/hinemo/a7902",
        bestseller: false,
        new: true,
      },
      {
        id: 68,
        code: "А-7910",
        name: "Папка-сумка двухсторонняя S",
        link: "https://lihitlab-jp.ru/images/A7910/A-7910.jpg",
        cardsRoute: "/catalog/hinemo/a7910",
        bestseller: false,
        new: true,
      },
      {
        id: 69,
        code: "А-7911",
        name: "Папка-сумка двухсторонняя M",
        link: "https://lihitlab-jp.ru/images/A7911/A-7911.jpg",
        cardsRoute: "/catalog/hinemo/a7911",
        bestseller: false,
        new: true,
      },
      {
        id: 70,
        code: "А-7912",
        name: "Папка-сумка двухсторонняя L",
        link: "https://lihitlab-jp.ru/images/A7912/A-7912.jpg",
        cardsRoute: "/catalog/hinemo/a7912",
        bestseller: false,
        new: true,
      },
      
    ]