export const A7701 =
    [
        {
            id: 1,
            code: "A-7701-3",
            name: "Клатч",
            link: "https://lihitlab-jp.ru/images/A7701/A-7701-3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50139719/detail.aspx",

        },
        {
            id: 2,
            code: "A-7701-7",
            name: "Клатч",
            link: "https://lihitlab-jp.ru/images/A7701/A-7701-7.jpg",
            color: "Зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50139720/detail.aspx",

        },
        {
            id: 3,
            code: "A-7701-11",
            name: "Клатч",
            link: "https://lihitlab-jp.ru/images/A7701/A-7701-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50139721/detail.aspx",

        },
        {
            id: 4,
            code: "A-7701-16",
            name: "Клатч",
            link: "https://lihitlab-jp.ru/images/A7701/A-7701-16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50139722/detail.aspx",

        },
       
    ]

export const A7701POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7701/A-7701_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7701/A-7701_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7701/A-7701_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7701/A-7701_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7701/A-7701_poi5.jpg",
        },

    ]