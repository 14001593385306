export const A7582 =
    [
        {
            id: 1,
            code: "A-7582-4",
            name: "Сумка c увеличивающейся толщиной B4",
            link: "https://lihitlab-jp.ru/images/A7582/A-7582-4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50224474/detail.aspx",

        },
        {
            id: 2,
            code: "A-7582-11",
            name: "Сумка c увеличивающейся толщиной B4",
            link: "https://lihitlab-jp.ru/images/A7582/A-7582-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50224475/detail.aspx",

        },
        {
            id: 3,
            code: "A-7582-22",
            name: "Сумка c увеличивающейся толщиной B4",
            link: "https://lihitlab-jp.ru/images/A7582/A-7582-22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50224476/detail.aspx",

        },
        {
            id: 4,
            code: "A-7582-24",
            name: "Сумка c увеличивающейся толщиной B4",
            link: "https://lihitlab-jp.ru/images/A7582/A-7582-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50224477/detail.aspx",

        },
       
    ]

export const A7582POI =
    [
      
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7582/A-7582_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7582/A-7582_poi2.jpg",
        },
        

    ]