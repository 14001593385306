export const N1674 =
    [
        {
            id: 1,
            code: "N-1674-0",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N1674/N-1674_0.jpg",
            color: "Белый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145725758/detail.aspx",
        },
        {
            id: 2,
            code: "N-1674-11",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N1674/N-1674_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145725753/detail.aspx",
        },
        {
            id: 3,
            code: "N-1674-24",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N1674/N-1674_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145725757/detail.aspx",
        },
        {
            id: 4,
            code: "N-1674-26",
            name: "Блокнот перекидной на пружине формата B5 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N1674/N-1674_26.jpg",
            color: "Серый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145725754/detail.aspx",
        },
        
       
    ]

export const N1674POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N1674/N-1674_poi1.jpg",
        },
        
            

    ]