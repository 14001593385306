export const A7724 =
    [
        {
            id: 1,
            code: "A-7724-1",
            name: "Блок для записей большой Медвежонок",
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_1.jpg",
            color: "Коричневый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50594873/detail.aspx",

        },
        {
            id: 2,
            code: "A-7724-2",
            name: "Блок для записей большой Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50594874/detail.aspx",
        },
        {
            id: 3,
            code: "A-7724-3",
            name: "Блок для записей большой Черный кот",
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50594875/detail.aspx",
        },
        {
            id: 4,
            code: "A-7724-4",
            name: "Блок для записей большой Серый кот",
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_4.jpg",
            color: "Серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50594876/detail.aspx",
        },
        {
            id: 5,
            code: "A-7724-5",
            name: "Блок для записей большой Свинка",
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_5.jpg",
            color: "Розовый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50594877/detail.aspx",
        },
        {
            id: 6,
            code: "A-7724-6",
            name: "Блок для записей большой Панда",
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_6.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50594878/detail.aspx",
        },
    ]


export const A7724POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_poi7.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_poi8.jpg",
        },
        {
            id: 9,
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_poi9.jpg",
        },
        {
            id: 10,
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_poi10.jpg",
        },
        {
            id: 11,
            link: "https://lihitlab-jp.ru/images/A7724/A-7724_poi11.jpg",
        },

    ]