export const A7911 =
    [
        {
            id: 3,
            code: "A-7911-11",
            name: "Папка-сумка двухсторонняя M",
            link: "https://lihitlab-jp.ru/images/A7911/A-7911_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145547464/detail.aspx",

        },
        {
            id: 4,
            code: "A-7911-16",
            name: "Папка-сумка двухсторонняя M",
            link: "https://lihitlab-jp.ru/images/A7911/A-7911_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145547465/detail.aspx",

        },
        {
            id: 5,
            code: "A-7911-24",
            name: "Папка-сумка двухсторонняя M",
            link: "https://lihitlab-jp.ru/images/A7911/A-7911_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145547463/detail.aspx",

        },
       
    ]

export const A7911POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7911/A-7911_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7911/A-7911_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7911/A-7911_poi3.jpg",
        },

    ]