export const A7731 =
    [
        {
            id: 1,
            code: "A-7731-0",
            name: "Пенал футляр большой",
            link: "https://lihitlab-jp.ru/images/A7731/A-7731-0.jpg",
            color: "Белый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50336779/detail.aspx",

        },
        {
            id: 2,
            code: "A-7731-3",
            name: "Пенал футляр большой",
            link: "https://lihitlab-jp.ru/images/A7731/A-7731-3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50336780/detail.aspx",

        },
        {
            id: 3,
            code: "A-7731-5",
            name: "Пенал футляр большой",
            link: "https://lihitlab-jp.ru/images/A7731/A-7731-5.jpg",
            color: "Желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50336781/detail.aspx",

        },
        {
            id: 4,
            code: "A-7731-10",
            name: "Пенал футляр большой",
            link: "https://lihitlab-jp.ru/images/A7731/A-7731-10.jpg",
            color: "Лавандовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50336782/detail.aspx",

        },
        {
            id: 5,
            code: "A-7731-11",
            name: "Пенал футляр большой",
            link: "https://lihitlab-jp.ru/images/A7731/A-7731-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50336783/detail.aspx",

        },
        {
            id: 6,
            code: "A-7731-19",
            name: "Пенал футляр большой",
            link: "https://lihitlab-jp.ru/images/A7731/A-7731-19.jpg",
            color: "Мятный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50336784/detail.aspx",

        },
       
       
    ]

export const A7731POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7731/A-7731_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7731/A-7731_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7731/A-7731_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7731/A-7731_poi4.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7731/A-7731_img1.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7731/A-7731_img2.jpg",
        },
        

    ]