export const A7716 =
    [
        {
            id: 1,
            code: "A-7716-1",
            name: "Пенал-косметичка Медвежонок",
            link: "https://lihitlab-jp.ru/images/A7716/A-7716-1.jpg",
            color: "Коричневый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50002192/detail.aspx",

        },
        {
            id: 2,
            code: "A-7716-2",
            name: "Пенал-косметичка Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7716/A-7716-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50002193/detail.aspx",
        },
        {
            id: 3,
            code: "A-7716-3",
            name: "Пенал-косметичка Черный кот",
            link: "https://lihitlab-jp.ru/images/A7716/A-7716-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50002194/detail.aspx",
        },
        {
            id: 4,
            code: "A-7716-4",
            name: "Пенал-косметичка Серый кот",
            link: "https://lihitlab-jp.ru/images/A7716/A-7716-4.jpg",
            color: "Серый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50002195/detail.aspx",
        },
        {
            id: 5,
            code: "A-7716-5",
            name: "Пенал-косметичка Свинка",
            link: "https://lihitlab-jp.ru/images/A7716/A-7716-5.jpg",
            color: "Розовый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50002196/detail.aspx",
        },
        {
            id: 6,
            code: "A-7716-6",
            name: "Пенал-косметичка Панда",
            link: "https://lihitlab-jp.ru/images/A7716/A-7716-6.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50002197/detail.aspx",
        },
    ]


export const A7716POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7716/A-7716_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7716/A-7716_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7716/A-7716_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7716/A-7716_poi4.jpg",
        },
              
    ]