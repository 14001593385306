export const A7583 =
    [
        
        {
            id: 2,
            code: "A-7583-11",
            name: "Сумка-шоппер B4/A4",
            link: "https://lihitlab-jp.ru/images/A7583/A-7583_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145522178/detail.aspx",

        },
        {
            id: 4,
            code: "A-7583-24",
            name: "Сумка-шоппер B4/A4",
            link: "https://lihitlab-jp.ru/images/A7583/A-7583_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145522178/detail.aspx",

        },
       
    ]

export const A7583POI =
    [
       
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7583/A-7583_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7583/A-7583_poi4.jpg",
        },
                     

    ]