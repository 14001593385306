export const A7727 =
    [
        {
            id: 2,
            code: "A-7727-2",
            name: "Держатель для кабеля Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7727/A-7727-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
        },
        {
            id: 3,
            code: "A-7727-3",
            name: "Держатель для кабеля Черный кот",
            link: "https://lihitlab-jp.ru/images/A7727/A-7727-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
        },
        {
            id: 7,
            code: "A-7727-7",
            name: "Держатель для кабеля Белый кот",
            link: "https://lihitlab-jp.ru/images/A7727/A-7727-7.jpg",
            color: "белый",
            bestseller: true,
            new: false,
        },
        {
            id: 5,
            code: "A-7727-8",
            name: "Держатель для кабеля Бостон терьер",
            link: "https://lihitlab-jp.ru/images/A7727/A-7727-8.jpg",
            color: "черный",
            bestseller: true,
            new: false,
        },
        {
            id: 9,
            code: "A-7727-6",
            name: "Держатель для кабеля Попугай",
            link: "https://lihitlab-jp.ru/images/A7727/A-7727-9.jpg",
            color: "Желто-зеленый",
            bestseller: true,
            new: false,
        },
        {
            id: 10,
            code: "A-7727-10",
            name: "Держатель для кабеля Пингвин",
            link: "https://lihitlab-jp.ru/images/A7727/A-7727-10.jpg",
            color: "Черно-серый",
            bestseller: true,
            new: false,
        },
    ]


export const A7727POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7727/A-7727_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7727/A-7727_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7727/A-7727_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7727/A-7727_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7727/A-7727_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7727/A-7727_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7727/A-7727_poi7.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7727/A-7727_poi8.jpg",
        },
        {
            id: 9,
            link: "https://lihitlab-jp.ru/images/A7727/A-7727_poi9.jpg",
        },
      

    ]