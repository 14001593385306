export const A7768 =
    [
        {
            id: 1,
            code: "A-7768-11",
            name: "Папка-сумка органайзер L",
            link: "https://lihitlab-jp.ru/images/A7768/A-7768_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137218985/detail.aspx",

        },
        {
            id: 2,
            code: "A-7768-22",
            name: "Папка-сумка органайзер L",
            link: "https://lihitlab-jp.ru/images/A7768/A-7768_22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137218981/detail.aspx?targetUrl=EX",

        },
        {
            id: 3,
            code: "A-7768-24",
            name: "Папка-сумка органайзер L",
            link: "https://lihitlab-jp.ru/images/A7768/A-7768_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137218989/detail.aspx",

        },

    ]

export const A7768POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7768/A-7768_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7767/A-7767_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7767/A-7767_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7767/A-7767_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7767/A-7767_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7767/A-7767_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7768/A-7768_poi2.jpg",
        },

    ]