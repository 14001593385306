export const SOFFICE = [
      {
        id: 90,
        code: "N-3100",
        name: "Блокнот перекидной на пружине формата B7",
        link: "https://lihitlab-jp.ru/images/N3100/N-3100.jpg",
        cardsRoute: "/catalog/soffice/n3100",
        bestseller: false,
        new: false,
      },
      {
        id: 91,
        code: "N-3101",
        name: "Блокнот перекидной на пружине формата A6",
        link: "https://lihitlab-jp.ru/images/N3101/N-3101.jpg",
        cardsRoute: "/catalog/soffice/n3101",
        bestseller: false,
        new: false,
      },
      {
        id: 92,
        code: "N-3103",
        name: "Блокнот перекидной на пружине формата A5",
        link: "https://lihitlab-jp.ru/images/N3103/N-3103.jpg",
        cardsRoute: "/catalog/soffice/n3103",
        bestseller: false,
        new: false,
      },
      {
        id: 93,
        code: "N-3104",
        name: "Блокнот перекидной на пружине формата B5",
        link: "https://lihitlab-jp.ru/images/N3104/N-3104.jpg",
        cardsRoute: "/catalog/soffice/n3104",
        bestseller: false,
        new: false,
      },
      
    ]