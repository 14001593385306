export const A7702 =
    [
        {
            id: 1,
            code: "A-7702-3",
            name: "Клатч большой",
            link: "https://lihitlab-jp.ru/images/A7702/A-7702-3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50140107/detail.aspx",

        },
        {
            id: 2,
            code: "A-7702-7",
            name: "Клатч большой",
            link: "https://lihitlab-jp.ru/images/A7702/A-7702-7.jpg",
            color: "Зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50140108/detail.aspx",

        },
        {
            id: 3,
            code: "A-7702-11",
            name: "Клатч большой",
            link: "https://lihitlab-jp.ru/images/A7702/A-7702-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50140109/detail.aspx",

        },
        {
            id: 4,
            code: "A-7702-16",
            name: "Клатч большой",
            link: "https://lihitlab-jp.ru/images/A7702/A-7702-16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50140110/detail.aspx",

        },
       
    ]

export const A7702POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7702/A-7702_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7702/A-7702_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7702/A-7702_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7702/A-7702_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7702/A-7702_poi5.jpg",
        },

    ]