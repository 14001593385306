export const A7751 =
    [
        {
            id: 1,
            code: "A-7751-11",
            name: "Сумка-органайзер вертикальная",
            link: "https://lihitlab-jp.ru/images/A7751/A-7751_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137189801/detail.aspx",

        },
        {
            id: 2,
            code: "A-7751-22",
            name: "Сумка-органайзер вертикальная",
            link: "https://lihitlab-jp.ru/images/A7751/A-7751_22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137189797/detail.aspx",

        },
        {
            id: 3,
            code: "A-7751-24",
            name: "Сумка-органайзер вертикальная",
            link: "https://lihitlab-jp.ru/images/A7751/A-7751_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137189795/detail.aspx",

        },

    ]

export const A7751POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7751/A-7751_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7751/A-7751_poi2.jpg",
        },
        
    ]