export const A7555 =
    [
        {
            id: 1,
            code: "A-7555-4",
            name: "Пенал-органайзер с двумя отделами",
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_4.jpg",
            color: "Оранжевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185761489/detail.aspx",

        },
        {
            id: 2,
            code: "A-7555-6",
            name: "Пенал-органайзер с двумя отделами",
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_6.jpg",
            color: "Желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185761490/detail.aspx",

        },
        {
            id: 4,
            code: "A-7555-24",
            name: "Пенал-органайзер с двумя отделами",
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185761491/detail.aspx",

        },
       
    ]

export const A7555POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_poi6.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_poi8.jpg",
        },
        {
            id: 9,
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_poi9.jpg",
        },
        {
            id: 10,
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_poi10.jpg",
        },
        {
            id: 11,
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_poi11.jpg",
        },
        {
            id: 12,
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_poi12.jpg",
        },
        {
            id: 13,
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_poi13.jpg",
        },
        {
            id: 14,
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_poi14.jpg",
        },
        {
            id: 15,
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_poi15.jpg",
        },
        {
            id: 16,
            link: "https://lihitlab-jp.ru/images/A7555/A-7555_poi16.jpg",
        },
      
        

    ]