export const N2993 =
    [
        {
            id: 1,
            code: "N-2993-9",
            name: "Блокнот на пружине с обложкой из искусстенной кожи формата А5 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N2993/N-2993_9.jpg",
            color: "Коричневый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164878518/detail.aspx",
        },
        {
            id: 2,
            code: "N-2993-16",
            name: "Блокнот на пружине с обложкой из искусстенной кожи формата А5 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N2993/N-2993_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164885224/detail.aspx",
        },
        {
            id: 3,
            code: "N-2993-27",
            name: "Блокнот на пружине с обложкой из искусстенной кожи формата А5 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N2993/N-2993_27.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/164878522/detail.aspx",
        },
       
       
    ]

export const N2993POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N2993/N-2993_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/N2993/N-2993_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/N2993/N-2993_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/N2993/N-2993_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/N2993/N-2993_poi5.jpg",
        },
      
      

    ]