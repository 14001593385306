export const A7752 =
    [
        {
            id: 1,
            code: "A-7752-11",
            name: "Сумка-органайзер",
            link: "https://lihitlab-jp.ru/images/A7752/A-7752_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137200238/detail.aspx",

        },
        {
            id: 2,
            code: "A-7752-22",
            name: "Сумка-органайзер",
            link: "https://lihitlab-jp.ru/images/A7752/A-7752_22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137200240/detail.aspx",

        },
        {
            id: 3,
            code: "A-7752-24",
            name: "Сумка-органайзер",
            link: "https://lihitlab-jp.ru/images/A7752/A-7752_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137200234/detail.aspx",

        },

    ]

export const A7752POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7752/A-7752_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7752/A-7752_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7752/A-7752_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7752/A-7752_poi4.jpg",
        },
        
    ]