export const HM521 =
    [
        {
            id: 1,
            code: "HM-521-blue",
            name: "Сумочка для документов А6",
            link: "https://lihitlab-jp.ru/images/HM521/HM521_blue.jpg",
            color: "Синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/146190960/detail.aspx",
        },
        {
            id: 1,
            code: "HM-521-red",
            name: "Сумочка для документов А6",
            link: "https://lihitlab-jp.ru/images/HM521/HM521_red.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/146190959/detail.aspx",
        },
      
       
       
    ]

export const HM521POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/HM521/HM521_poi1.jpg",
        },    
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/HM521/HM521_poi2.jpg",
        },    
       

    ]