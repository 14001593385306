export const CATALOG =
  [
    {
      id: 1,
      directory: "PuniLabo",
      directoryRoute: "/catalog/punilabo",
      subdirectory: [
        {
          productName: "Пенал-тубус малый А-7712",
          productRout: "/catalog/punilabo/a7712",
        },
        {
          productName: "Подставка для смартфона А-7713",
          productRout: "/catalog/punilabo/a7713",
        },
        {
          productName: "Пенал-тубус большой А-7714",
          productRout: "/catalog/punilabo/a7714",
        },
        {
          productName: "Пенал-косметичка А-7716",
          productRout: "/catalog/punilabo/a7716",
        },
        {
          productName: "Пенал узкий, длинный А-7717",
          productRout: "/catalog/punilabo/a7717",
        },
        {
          productName: "Брелок с звуковой сигнализацией А-7718",
          productRout: "/catalog/punilabo/a7718",
        },
        {
          productName: "Пенал с ремешком А-7720",
          productRout: "/catalog/punilabo/a7720",
        },
        {
          productName: "Магнитная клипса А-7721",
          productRout: "/catalog/punilabo/a7721",
        },
        {
          productName: "Стягиватель А-7722",
          productRout: "/catalog/punilabo/a7722",
        },
        {
          productName: "Блок для записей А-7723",
          productRout: "/catalog/punilabo/a7723",
        },
        {
          productName: "Блок для записей большой А-7724",
          productRout: "/catalog/punilabo/a7724",
        },
        {
          productName: "Магнит канцелярский А-7725",
          productRout: "/catalog/punilabo/a7725",
        },
        {
          productName: "Держатель для кабеля А-7727",
          productRout: "/catalog/punilabo/a7727",
        },
        {
          productName: "Очиститель экрана А-7728",
          productRout: "/catalog/punilabo/a7728",
        },
        {
          productName: "Пенал лежащий, мини А-7780",
          productRout: "/catalog/punilabo/a7780",
        },
        {
          productName: "Пенал лежащий, длинный А-7781",
          productRout: "/catalog/punilabo/a7781",
        },
        {
          productName: "Пенал яйцо А-7782",
          productRout: "/catalog/punilabo/a7782",
        },
        {
          productName: "Пенал яйцо, большой А-7783",
          productRout: "/catalog/punilabo/a7783",
        },
        {
          productName: "Кошелек А-7784",
          productRout: "/catalog/punilabo/a7784",
        },
        {
          productName: "Ключница А-7785",
          productRout: "/catalog/punilabo/a7785",
        },
        {
          productName: "Пенал лежащий, большой А-7800",
          productRout: "/catalog/punilabo/a7800",
        },
        {
          productName: "Ножницы А-7803",
          productRout: "/catalog/punilabo/a7803",
        },
        {
          productName: "Бейджик А-7804",
          productRout: "/catalog/punilabo/a7804",
        },
      ]

    },

    {
      id: 2,
      directory: "ALTNA",
      directoryRoute: "/catalog/altna",
      subdirectory: [
        {
          productName: "Бизнес-сумка А-7760",
          productRout: "/catalog/altna/a7760",
        },
        {
          productName: "Сумка-органайзер горизонтальная А-7750",
          productRout: "/catalog/altna/a7750",
        },
        {
          productName: "Сумка-органайзер вертикальная А-7751",
          productRout: "/catalog/altna/a7751",
        },
        {
          productName: "Сумка-органайзер А-7752",
          productRout: "/catalog/altna/a7752",
        },
        {
          productName: "Папка-сумка складная А-7773",
          productRout: "/catalog/altna/a7773",
        },
        {
          productName: "Крючек для сумки А-7791",
          productRout: "/catalog/altna/a7791",
        },
        {
          productName: "Папка-сумка органайзер M А-7767",
          productRout: "/catalog/altna/a7767",
        },
        {
          productName: "Папка-сумка органайзер L А-7768",
          productRout: "/catalog/altna/a7768",
        },
        {
          productName: "Пенал-тубус с магнитной крышкой А-7759",
          productRout: "/catalog/altna/a7759",
        },
        {
          productName: "Держатель смартфона А-7790",
          productRout: "/catalog/altna/a7790",
        },
        {
          productName: "Пенал-органайзер А-7764",
          productRout: "/catalog/altna/a7764",
        },
        {
          productName: "Пенал-органайзер большой А-7765",
          productRout: "/catalog/altna/a7765",
        },

      ]
    },

    {
      id: 3,
      directory: "SmartFit",
      directoryRoute: "/catalog/smartfit",
      subdirectory: [
        {
          productName: "Папка-сумка для гаджетов А5 А-7575",
          productRout: "/catalog/smartfit/a7575",
        },
        {
          productName: "Папка-сумка для гаджетов B5 А-7576",
          productRout: "/catalog/smartfit/a7576",
        },
        {
          productName: "Папка-сумка для гаджетов А4 А-7577",
          productRout: "/catalog/smartfit/a7577",
        },
        {
          productName: "Блокнот-органайзер B5 N-1627",
          productRout: "/catalog/smartfit/n1627",
        },
        {
          productName: "Блокнот-органайзер A5 N-1647",
          productRout: "/catalog/smartfit/n1647",
        },
        {
          productName: "Пенал-портмоне A-7585",
          productRout: "/catalog/smartfit/a7585",
        },
        {
          productName: "Сумка c увеличивающейся толщиной B4 A-7581",
          productRout: "/catalog/smartfit/a7581",
        },
        {
          productName: "Сумка c увеличивающейся толщиной B4 A-7582",
          productRout: "/catalog/smartfit/a7582",
        },
        {
          productName: "Сумка-шоппер B4/A4 A-7583",
          productRout: "/catalog/smartfit/a7583",
        },
        {
          productName: "Крючек для сумки A-7595",
          productRout: "/catalog/smartfit/a7595",
        },
        {
          productName: "Сумка B4 c удлиняющейся ручкой A-7650",
          productRout: "/catalog/smartfit/a7650",
        },
        {
          productName: "Сумка B4 c удлиняющейся ручкой A-7651",
          productRout: "/catalog/smartfit/a7651",
        },
        {
          productName: "Папка-сумка органайзер А4 A-7554",
          productRout: "/catalog/smartfit/a7554",
        },
        {
          productName: "Папка-сумка органайзер А5 A-7558",
          productRout: "/catalog/smartfit/a7558",
        },
        {
          productName: "Папка-сумка органайзер А4 A-7559",
          productRout: "/catalog/smartfit/a7559",
        },
        {
          productName: "Пенал-органайзер с одним отделом A-7551",
          productRout: "/catalog/smartfit/a7551",
        },
        {
          productName: "Пенал-органайзер с одним отделом узкий A-7552",
          productRout: "/catalog/smartfit/a7552",
        },
        {
          productName: "Пенал-органайзер с двумя отделами A-7555",
          productRout: "/catalog/smartfit/a7555",
        },
        {
          productName: "Пенал-органайзер с тремя отделами A-7556",
          productRout: "/catalog/smartfit/a7556",
        },
        {
          productName: "Пенал-органайзер с двумя отделами узкий A-7557",
          productRout: "/catalog/smartfit/a7557",
        },
        {
          productName: "Пенал плоский A-7652",
          productRout: "/catalog/smartfit/a7652",
        },
        {
          productName: "Пенал плоский большой A-7653",
          productRout: "/catalog/smartfit/a7653",
        },

      ]
    },
    {
      id: 4,
      directory: "ACTACT",
      directoryRoute: "/catalog/actact",
      subdirectory: [
        {
          productName: "Пенал-тубус А-7694",
          productRout: "/catalog/actact/a7694",
        },
        {
          productName: "Пенал-тубус большоой А-7695",
          productRout: "/catalog/actact/a7695",
        },
        {
          productName: "Сумка-портфель горизонтальная А-7670",
          productRout: "/catalog/actact/a7670",
        },
        {
          productName: "Сумка-портфель вертикальная А-7672",
          productRout: "/catalog/actact/a7672",
        },
        {
          productName: "Папка-сумка органайзер горизонтальная А5 А-7680",
          productRout: "/catalog/actact/a7680",
        },
        {
          productName: "Папка-сумка органайзер горизонтальная А4 А-7681",
          productRout: "/catalog/actact/a7681",
        },
        {
          productName: "Папка-сумка органайзер вертикальная А5 А-7682",
          productRout: "/catalog/actact/a7682",
        },
        {
          productName: "Папка-сумка органайзер вертикальная А4 А-7683",
          productRout: "/catalog/actact/a7683",
        },
        {
          productName: "Пенал-органайзер дорожный А-7686",
          productRout: "/catalog/actact/a7686",
        },
        {
          productName: "Пенал-органайзер плоский А-7687",
          productRout: "/catalog/actact/a7687",
        },
        {
          productName: "Пенал-косметичка А-7688",
          productRout: "/catalog/actact/a7688",
        },



      ]
    },
    {
      id: 5,
      directory: "HINEMO",
      directoryRoute: "/catalog/hinemo",
      subdirectory: [
        {
          productName: "Пенал-косметичка M А-7900",
          productRout: "/catalog/hinemo/a7900",
        },
        {
          productName: "Пенал-косметичка L А-7901",
          productRout: "/catalog/hinemo/a7901",
        },
        {
          productName: "Пенал двухсторонний L-образный А-7902",
          productRout: "/catalog/hinemo/a7902",
        },
        {
          productName: "Папка-сумка двухсторонняя S А-7910",
          productRout: "/catalog/hinemo/a7910",
        },
        {
          productName: "Папка-сумка двухсторонняя M А-7911",
          productRout: "/catalog/hinemo/a7911",
        },
        {
          productName: "Папка-сумка двухсторонняя L А-7912",
          productRout: "/catalog/hinemo/a7912",
        },


      ]
    },

    {
      id: 5,
      directory: "Bloomin",
      directoryRoute: "/catalog/bloomin",
      subdirectory: [
        {
          productName: "",
          productRout: "",
        },



      ]
    },

    {
      id: 5,
      directory: "AQUA DROPs",
      directoryRoute: "/catalog/aqua-drops",
      subdirectory: [
        {
          productName: "",
          productRout: "",
        },



      ]
    },

    {
      id: 5,
      directory: "ALCLEA",
      directoryRoute: "/catalog/alclea",
      subdirectory: [
        {
          productName: "",
          productRout: "",
        },



      ]
    },

    {
      id: 5,
      directory: "Блокноты",
      directoryRoute: "/catalog/note-book",
      subdirectory: [
        {
          productName: "",
          productRout: "",
        },



      ]
    },

    {
      id: 5,
      directory: "CUBE FIZZ",
      directoryRoute: "/catalog/cube-fiz",
      subdirectory: [
        {
          productName: "",
          productRout: "",
        },



      ]
    },

    {
      id: 5,
      directory: "Hirakuno",
      directoryRoute: "/catalog/hirakuno",
      subdirectory: [
        {
          productName: "",
          productRout: "",
        },



      ]
    },

    {
      id: 5,
      directory: "Soffice",
      directoryRoute: "/catalog/soffice",
      subdirectory: [
        {
          productName: "",
          productRout: "",
        },



      ]
    },
    {
      id: 5,
      directory: "Pastello",
      directoryRoute: "/catalog/pastello",
      subdirectory: [
        {
          productName: "",
          productRout: "",
        },



      ]
    },

    {
      id: 5,
      directory: "Канцелярские товары",
      directoryRoute: "/catalog/kanctovary",
      subdirectory: [
        {
          productName: "",
          productRout: "",
        },



      ]
    },

  ]

