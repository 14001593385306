export const N1672 =
    [
        {
            id: 1,
            code: "N-1672-0",
            name: "Блокнот перекидной на пружине формата B6 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N1672/N-1672_0.jpg",
            color: "Белый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145721296/detail.aspx",
        },
        {
            id: 2,
            code: "N-1672-11",
            name: "Блокнот перекидной на пружине формата B6 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N1672/N-1672_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145721295/detail.aspx",
        },
        {
            id: 3,
            code: "N-1672-24",
            name: "Блокнот перекидной на пружине формата B6 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N1672/N-1672_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145721297/detail.aspx",
        },
        {
            id: 4,
            code: "N-1672-26",
            name: "Блокнот перекидной на пружине формата B6 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N1672/N-1672_26.jpg",
            color: "Серый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145721294/detail.aspx",
        },
        
       
    ]

export const N1672POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N1672/N-1672_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/N1672/N-1672_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/N1672/N-1672_poi3.jpg",
        },
            

    ]