export const A7803 =
    [
        {
            id: 2,
            code: "A-7803-2",
            name: "Ножницы Песик Сиба",
            link: "https://lihitlab-jp.ru/images/A7803/A-7803-2.jpg",
            color: "Кукурузный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49561116/detail.aspx",
        },
        {
            id: 3,
            code: "A-7803-3",
            name: "Ножницы Черный кот",
            link: "https://lihitlab-jp.ru/images/A7803/A-7803-3.jpg",
            color: "Черный",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49561117/detail.aspx",
        },
        {
            id: 7,
            code: "A-7803-7",
            name: "Ножницы Белый кот",
            link: "https://lihitlab-jp.ru/images/A7803/A-7803-7.jpg",
            color: "белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49561118/detail.aspx",
        },
        {
            id: 16,
            code: "A-7803-16",
            name: "Ножницы Морской Котик",
            link: "https://lihitlab-jp.ru/images/A7803/A-7803-16.jpg",
            color: "Белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49561119/detail.aspx",
        },
        {
            id: 17,
            code: "A-7803-17",
            name: "Ножницы Цыпленок",
            link: "https://lihitlab-jp.ru/images/A7803/A-7803-17.jpg",
            color: "Желтый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49561120/detail.aspx",
        },
        {
            id: 18,
            code: "A-7803-18",
            name: "Ножницы Песик Мопс",
            link: "https://lihitlab-jp.ru/images/A7803/A-7803-18.jpg",
            color: "белый",
            bestseller: true,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49561121/detail.aspx",
        },
        
        

    ]


export const A7803POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7803/A-7803_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7803/A-7803_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7803/A-7803_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7803/A-7803_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7803/A-7803_poi5.jpg",
        },
    

    ]