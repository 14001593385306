export const N2641 =
    [
        {
            id: 1,
            code: "N-2641-5",
            name: "Блокнот перекидной на пружине формата А5 со сменными листами (70 листов)",
            link: "https://lihitlab-jp.ru/images/N2641/N-2641_5.jpg",
            color: "Желттый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163764355/detail.aspx",
        },
        {
            id: 2,
            code: "N-2641-7",
            name: "Блокнот перекидной на пружине формата А5 со сменными листами (70 листов)",
            link: "https://lihitlab-jp.ru/images/N2641/N-2641_7.jpg",
            color: "Зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163764356/detail.aspx",
        },
        {
            id: 3,
            code: "N-2641-8",
            name: "Блокнот перекидной на пружине формата А5 со сменными листами (70 листов)",
            link: "https://lihitlab-jp.ru/images/N2641/N-2641_8.jpg",
            color: "Синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163764357/detail.aspx",
        },
        {
            id: 4,
            code: "N-2641-10",
            name: "Блокнот перекидной на пружине формата А5 со сменными листами (70 листов)",
            link: "https://lihitlab-jp.ru/images/N2641/N-2641_10.jpg",
            color: "Фиолетовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163764358/detail.aspx",
        },
        {
            id: 5,
            code: "N-2641-11",
            name: "Блокнот перекидной на пружине формата А5 со сменными листами (70 листов)",
            link: "https://lihitlab-jp.ru/images/N2641/N-2641_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163764359/detail.aspx",
        },
        {
            id: 6,
            code: "N-2641-12",
            name: "Блокнот перекидной на пружине формата А5 со сменными листами (70 листов)",
            link: "https://lihitlab-jp.ru/images/N2641/N-2641_12.jpg",
            color: "Розовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163764360/detail.aspx",
        },
        {
            id: 7,
            code: "N-2641-16",
            name: "Блокнот перекидной на пружине формата А5 со сменными листами (70 листов)",
            link: "https://lihitlab-jp.ru/images/N2641/N-2641_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/163764361/detail.aspx",
        },
        
       
    ]

export const N2641POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N2641/N-2641_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/N2641/N-2641_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/N2641/N-2641_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/N2641/N-2641_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/N2641/N-2641_poi5.jpg",
        },
            

    ]