import './Cardpoi.css';

function Cardpoi(props) {




  return (

    <article className="catalog__cardpoi">

      <img className="catalog__imgpoi" src={props.link} />
          


    </article>

  );
}

export default Cardpoi;
