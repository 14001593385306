export const A7790 =
    [
        {
            id: 1,
            code: "A-7790-11",
            name: "Держатель смартфона",
            link: "https://lihitlab-jp.ru/images/A7790/A-7790_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/139447736/detail.aspx",

        },
        {
            id: 2,
            code: "A-7790-22",
            name: "Держатель смартфона",
            link: "https://lihitlab-jp.ru/images/A7790/A-7790_22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/139447737/detail.aspx",

        },
        {
            id: 3,
            code: "A-7790-24",
            name: "Держатель смартфона",
            link: "https://lihitlab-jp.ru/images/A7790/A-7790_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/139447735/detail.aspx",
        },

    ]

export const A7790POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7790/A-7790_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7790/A-7790_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7790/A-7790_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7790/A-7790_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7790/A-7790_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7790/A-7790_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7790/A-7790_poi7.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7790/A-7790_poi8.jpg",
        },
        {
            id: 9,
            link: "https://lihitlab-jp.ru/images/A7790/A-7790_poi9.jpg",
        },
        {
            id: 10,
            link: "https://lihitlab-jp.ru/images/A7790/A-7790_poi10.jpg",
        },
        {
            id: 11,
            link: "https://lihitlab-jp.ru/images/A7790/A-7790_poi11.jpg",
        },
        {
            id: 12,
            link: "https://lihitlab-jp.ru/images/A7790/A-7790_poi12.jpg",
        },
        {
            id: 13,
            link: "https://lihitlab-jp.ru/images/A7790/A-7790_poi13.jpg",
        },

    ]