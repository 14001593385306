export const F7739 =
    [
        {
            id: 2,
            code: "F-7739-3",
            name: "Папка",
            link: "https://lihitlab-jp.ru/images/F7739/F-7739-3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49928416/detail.aspx",

        },
        {
            id: 5,
            code: "F-7739-5",
            name: "Папка",
            link: "https://lihitlab-jp.ru/images/F7739/F-7739-5.jpg",
            color: "Желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49928417/detail.aspx",

        },
        {
            id: 5,
            code: "F-7739-11",
            name: "Папка",
            link: "https://lihitlab-jp.ru/images/F7739/F-7739-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49928419/detail.aspx",

        },
        {
            id: 6,
            code: "F-7739-19",
            name: "Папка",
            link: "https://lihitlab-jp.ru/images/F7739/F-7739-19.jpg",
            color: "Мятный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/49928420/detail.aspx",

        },
       
       
    ]

export const F7739POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/F7739/F-7739_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/F7739/F-7739_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/F7739/F-7739_poi3.jpg",
        },
     
    ]