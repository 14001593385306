export const A7695 =
    [
        {
            id: 1,
            code: "A-7695-3",
            name: "Пенал-тубус овальный большой",
            link: "https://lihitlab-jp.ru/images/A7695/A-7695-3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50236207/detail.aspx",

        },
        {
            id: 3,
            code: "A-7695-6",
            name: "Пенал-тубус овальный большой",
            link: "https://lihitlab-jp.ru/images/A7695/A-7695-6.jpg",
            color: "Желто-зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50236208/detail.aspx",

        },
        {
            id: 4,
            code: "A-7695-11",
            name: "Пенал-тубус овальный большой",
            link: "https://lihitlab-jp.ru/images/A7695/A-7695-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50236209/detail.aspx",

        },
        {
            id: 6,
            code: "A-7695-24",
            name: "Пенал-тубус овальный большой",
            link: "https://lihitlab-jp.ru/images/A7695/A-7695-24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/50236210/detail.aspx",

        },
       
    ]

export const A7695POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7695/A-7695_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7695/A-7695_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7695/A-7695_poi3.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7695/A-7695_img1.jpg",
        },        

    ]