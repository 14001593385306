export const A7737 =
    [
        {
            id: 2,
            code: "A-7737-3",
            name: "Сумочка-косметичка",
            link: "https://lihitlab-jp.ru/images/A7737/A-7737-3.jpg",
            color: "Красный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/53897621/detail.aspx",

        },
        {
            id: 3,
            code: "A-7737-5",
            name: "Сумочка-косметичка",
            link: "https://lihitlab-jp.ru/images/A7737/A-7737-5.jpg",
            color: "Желтый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/53897622/detail.aspx",

        },
        {
            id: 4,
            code: "A-7737-10",
            name: "Сумочка-косметичка",
            link: "https://lihitlab-jp.ru/images/A7737/A-7737-10.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/53897623/detail.aspx",

        },
        {
            id: 5,
            code: "A-7737-11",
            name: "Сумочка-косметичка",
            link: "https://lihitlab-jp.ru/images/A7737/A-7737-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/53897624/detail.aspx",

        },
        {
            id: 6,
            code: "A-7737-19",
            name: "Сумочка-косметичка",
            link: "https://lihitlab-jp.ru/images/A7737/A-7737-19.jpg",
            color: "Мятный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/53897625/detail.aspx",

        },
       
       
    ]

export const A7737POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7737/A-7737_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7737/A-7737_poi2.jpg",
        },
     
    ]