import './Baner.css';


import mainBaner from "../../images/baner.jpg";
import mainBaner2 from "../../images/baner2.png";


function Baner() {
  return (
  <div className="baner">

    <img src={mainBaner} className="baner__img" alt="Банер LIHIT LAB"/>
    
  </div>
  );
}

export default Baner;
