export const N1673 =
    [
        {
            id: 1,
            code: "N-1673-0",
            name: "Блокнот перекидной на пружине формата A5 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N1673/N-1673_0.jpg",
            color: "Белый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145722893/detail.aspx",
        },
        {
            id: 2,
            code: "N-1673-11",
            name: "Блокнот перекидной на пружине формата A5 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N1673/N-1673_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145722891/detail.aspx",
        },
        {
            id: 3,
            code: "N-1673-24",
            name: "Блокнот перекидной на пружине формата A5 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N1673/N-1673_24.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145722894/detail.aspx",
        },
        {
            id: 4,
            code: "N-1673-26",
            name: "Блокнот перекидной на пружине формата A5 со сменными листами (50 листов)",
            link: "https://lihitlab-jp.ru/images/N1673/N-1673_26.jpg",
            color: "Серый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/145722892/detail.aspx",
        },
        
       
    ]

export const N1673POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/N1673/N-1673_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/N1673/N-1673_poi2.jpg",
        },
      
            

    ]