export const A7556 =
    [
        {
            id: 4,
            code: "A-7556-24",
            name: "Пенал-органайзер с двумя отделами",
            link: "https://lihitlab-jp.ru/images/A7556/A-7556.jpg",
            color: "Черный",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/185931469/detail.aspx",

        },
       
    ]

export const A7556POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi6.jpg",
        },
        {
            id: 8,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi8.jpg",
        },
        {
            id: 9,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi9.jpg",
        },
        {
            id: 10,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi10.jpg",
        },
        {
            id: 11,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi11.jpg",
        },
        {
            id: 12,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi12.jpg",
        },
        {
            id: 13,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi13.jpg",
        },
        {
            id: 14,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi14.jpg",
        },
        {
            id: 15,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi15.jpg",
        },
        {
            id: 16,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi16.jpg",
        },
        {
            id: 17,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi17.jpg",
        },
        {
            id: 18,
            link: "https://lihitlab-jp.ru/images/A7556/A-7556_poi18.jpg",
        },
      
        

    ]