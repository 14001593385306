export const A7767 =
    [
        {
            id: 1,
            code: "A-7767-11",
            name: "Папка-сумка органайзер M",
            link: "https://lihitlab-jp.ru/images/A7767/A-7767_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137217544/detail.aspx",

        },
        {
            id: 2,
            code: "A-7767-22",
            name: "Папка-сумка органайзер M",
            link: "https://lihitlab-jp.ru/images/A7767/A-7767_22.jpg",
            color: "Оливковый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137217549/detail.aspx",

        },
        {
            id: 3,
            code: "A-7767-24",
            name: "Папка-сумка органайзер M",
            link: "https://lihitlab-jp.ru/images/A7767/A-7767_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/137217543/detail.aspx",

        },

    ]

export const A7767POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A7767/A-7767_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A7767/A-7767_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A7767/A-7767_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A7767/A-7767_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A7767/A-7767_poi5.jpg",
        },
        {
            id: 6,
            link: "https://lihitlab-jp.ru/images/A7767/A-7767_poi6.jpg",
        },
        {
            id: 7,
            link: "https://lihitlab-jp.ru/images/A7767/A-7767_poi7.jpg",
        },

    ]